.admin-home-right-side-content {
    flex: 1;
    width: 0;
    height: 100%;
}

.grid-criteria-container{
    overflow-y: scroll;
}
.criteria-button{
    padding: 10px 18px;
}