.reorder-page {
    background: var(--background-primary-color);
    justify-content: center;
    padding: 1rem;
}

.reorder-page-container {
    background: #FFFFFF;
    box-shadow: 2px 2px #f0f2f5;
    flex: 1;
    gap: 32px;
    max-width: 800px;
    padding: 3rem;
}

.reorder-container {
    background: #ffffff;
}