.product-configuration-container {
    align-items: center;
    background: #F2F4F7;
    display: flex;
    flex-flow: column;
    padding-bottom: 2rem;
}

.product-configuration-content-container {
    display: flex;
    flex-direction: column;
    width: 75%;
    max-width: 1320px;
    justify-content: space-between;
    align-items: stretch;
    gap: 1rem;
}

@media (max-width: 1200px) {
    .product-configuration-content-container {
        width: 80%;
    }
}

@media (max-width: 992px) {
    .product-configuration-content-container {
        width: 85%;
    }
}

@media (max-width: 768px) {
    .product-configuration-content-container {
        width: 90%;
    }
}

@media (max-width: 576px) {
    .product-configuration-content-container {
        width: 95%;
    }
}

.product-configuration-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}