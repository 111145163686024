
.radio-select-with-boxed-description-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.boxed-description-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}