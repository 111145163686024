

@media (max-width: 768px) {
    .payment-details-credit-card-details-entry-container button {
        width: 100%;
    }
}
.payment-details-credit-card-details-entry-container {
    background-color: #F2F4F7;
    padding: 1rem 2rem;
}

.payment-details-entry-input-container {
    align-items: start;
    display: flex;
    flex-flow: column;
}

.payment-details-entry-input-container label {
    margin-bottom: 0;
    width: 150px;
}
.payment-details-entry-input-container input {
    margin-right: 1rem;
    width: 250px;
}