
.micr-details-action-button-container {
    display: flex;
}
.micr-details-action-button-container button {
    margin-left: 10px;
}

.micr-details-input-column {
    align-items: center;
    display: flex;
}

.micr-details-input-label {
    margin: 0;
}

.micr-details-input {
    margin-left: 15px;
    width: 150px;
}

.micr-details-template-edit {
    margin-top: 5rem;
    margin-bottom: 1rem;
}

.show-tooltip {
    display: block;
}

.micr-details-input-matches-on-selector-container {
    margin: 1rem 0;
}

.micr-details-input-matches-on-selector-label {
    margin-left: 1rem;
}

.micr-details-financial-instutition-name {
    font-size: 140%;
    font-weight: bold;
}