
.cart-checkout-container {
	align-items: center;
	background: #F2F4F7;
	display: flex;
	flex-flow: column;
	padding-bottom: 2rem;
}

.cart-checkout-header-container {
	align-items: baseline;
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
}

.cart-checkout-content-container {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	gap: 2rem;
}

.cart-checkout-lane-one {
	width: 65%;
	padding: 1rem 0 0 0 ;
}

.cart-checkout-lane-two {
	display: flex;
	flex-direction: column;
	width: 30%;
}

.cart-checkout-payment-disclaimer {
	font-style: italic;
	text-align: center;
	width: 50%;
	padding-left: .5rem;
	padding-right: .5rem;
}

.cart-checkout-shipping-disclaimer {
	font-style: italic;
	margin-top: 5%;
	text-align: center;
}

.cart-checkout-invalid-input {
	color: red;
	font-size: 105%;
	padding: 1rem;
	text-align: center;
	width: 100%;
}

.cart-checkout-proceed-container {
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: wrap;
}

.cart-checkout-button-container {
	width: 50%;
}

.cart-checkout-proceed-button {
	width: 100%;
}

.cart-checkout-proceed-button:disabled {
	background-color: var(--primary-color);
	opacity: 0.5;
}

/* Mobile. ========================================= */

@media (max-width: 768px) {
	.cart-checkout-content-container {
		flex-direction: column;
	}

	.cart-checkout-lane-one {
		width: 100%;
	}

	.cart-checkout-lane-two {
		width: 100%;
	}

	.cart-checkout-payment-disclaimer {
		width: 100%;
		margin-top: 1rem;
	}

	.cart-checkout-button-container {
		width: 100%;
	}
}
