.grid-criteria-container{
    overflow-y: scroll;
}
.grid-page-right-side-content{
    flex: 1;
    width: 0;
    height: 100%;
}
.criteria-toggle-button{
    padding: 10px 18px;
}