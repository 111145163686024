
.payment-selection-card-header {
    align-items: center;
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
}


@media (max-width: 768px) {
    .payment-method-selection-options button {
        width: 100%;
    }
}

.payment-method-selection-options {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
}

.payment-method-selection-options button {
    margin: 0.5rem 1rem 0.5rem 0;
}

.payment-selection-option-container {
    display: flex;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
}

.payment-selection-option-container span {
    margin-right: 2rem;
}