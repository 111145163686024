
.colored-radio-selected-input-container {
  margin: 1rem 0;
}

.circular-radio-button {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.colored-option-container {
  display: flex;
  flex-wrap: nowrap;
}

.colored-option-content {
  cursor: pointer;
  height: 35px;
  margin: 0.5rem;
}

.circular-outline {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  background: #FFFFFF;
  border-radius: 50%;
}

.unselected-option {
  border: 1px dashed #98A2B3;
}

.selected-option {
  border: 2px solid #1D2939;
}

.colored-option-hide {
  display: none;
}