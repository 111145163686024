.homepage-title-container {
    margin: 5% 0;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .homepage-title {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .container{
        padding-left: 16px;
        padding-right: 16px;
    }
}

