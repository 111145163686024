.category-header-background{
    background: var(--category-header-background);
}

.category-header-container {
    display: flex;
    flex-direction: column;
}

.category-view-name {
    color: var(--category-header-color);
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 700;
}

.category-view-child-categories {
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 30px));
    gap: 40px;
    width: 100%;
}

@media (max-width: 768px) {
    .category-view-child-categories {
        grid-template-columns: repeat(2, calc(50% - 10px));
        gap: 20px;
    }
}

.category-view-child-category-name {
    color: var(--category-child-color);
    font-size: 24px;
    font-weight: 600;
    padding: 1.5rem 0;
    text-align: center;
}

.category-view-child-category-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {
    .category-view-child-category-image-container {
        max-height: 175px;
        max-width: 175px;
    }
}

@media (max-width: 768px) {
    .category-view-child-category-image-container {
        max-height: 125px;
        max-width: 125px;
    }
}

.category-view-child-category-image-container {
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}

.category-view-child-category-image-container img {
    display: inline;
    margin: 0 auto;
    height: 100%;
    object-fit: cover;
    width: 100%;
}