
.product-search-item-container a {
	color: #475467;
	width: 100%;
}

.product-search-item-container a:hover {
	color: #1D428A;
}

.product-search-item-card {
	border: none;
	height: 100%;
}

.product-search-item-name {
	font-weight: bold;
}

.product-search-item-image {
	display: flex;
	cursor: pointer;
}

.product-search-item-image img {
	max-width: 100%;
	padding: 4px;
}

.product-search-item-image img:hover {
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
