

.input-text-container {
    margin-bottom: 2.5rem;
    width: 100%;
}

/*Move these into root css*/

@media (min-width: 768px) {
    .half-width-text-input {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .half-width-text-input {
        width: 100%;
    }
}


.full-width-text-input {
    width: 100%;
}

input:focus::placeholder {
    color: transparent;
}

.text-input-read-only-value {
    width: 50%;
}

.invalid-text-input {
    border: 2px solid red;
}

.form-control.warning-text-input {
    border: 2px solid #ffeb3b;
    background-image: none;
}

.warning-text-input {
    border: 2px solid #ffeb3b;
}

.form-control.warning-text-input:focus {
    outline: none !important;
    border:2px #ffeb3b;
    box-shadow: 0 0 10px orange;
}

.text-input-warning-tooltip {
    background: #0A4E8A;
}

.invalid-text-input:focus {
    outline: none !important;
    border:2px solid red;
    box-shadow: 0 0 10px #ce717c;
}

.input-text-spinner-container .spinner-border {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
}

.not-yet-validated-text-input {
    /*border: 2px solid #ffeb3b;*/
}

.not-yet-validated-text-input:focus {
    outline: none !important;
    /*border:2px solid #ffeb3b;*/
    /*box-shadow: 0 0 10px #ceba71;*/
}

.validated-text-input {
    border: 2px solid green;
}

.validated-text-input:focus {
    outline: none !important;
    border:2px solid green;
    box-shadow: 0 0 10px green;
}

.text-input-label {
    font-weight: var(--h5-font-weight) !important;
    font-size: var(--h5-font-size) !important; 
}

.text-input-edit-mode-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}