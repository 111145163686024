
.saved-payment-selection-option-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.saved-payment-selection-option {
    align-items: center;
    display: flex;
}

.saved-payment-selection-option input:hover {
    cursor: pointer;
}

.saved-payment-selection-option-input-container {
    align-items: center;
    display: flex;
    margin: 0.5rem 0;
}

@media (max-width: 768px) {
    .saved-payment-selection-option-input-container {
        justify-content: space-between;
    }
}

.saved-payment-selection-option-label {
    margin: 0 1rem;
}

@media (min-width: 768px) {
    .saved-payment-details-text-container {
        min-width: 400px;
    }
}

.saved-payment-details-text-container {
    display: flex;
    flex-flow: column;
    font-size: 105%;
}

.saved-payment-details-text-container:hover {
    cursor: pointer;
}

.saved-payment-details-card-number-preview {
    font-weight: bold;
}

.saved-payment-details-address-value {
    color: #999999;
}