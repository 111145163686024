.ca-prop-page {
    background: var(--background-primary-color);
}

.ca-prop-container {
    background: #ffffff;
    padding: 50px;
    gap: 32px;
    margin: auto;
    flex: 1;
    box-shadow: 2px 2px #f0f2f5;
    margin-bottom: 64px;
}

.ca-prop-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #101828;
}

.ca-prop-body {
    gap: 20px;
}

.ca-prop-body-link {
   color: #2C88D9;
   text-decoration: underline; 
}

@media (max-width: 768px) {
    
    .ca-prop-container {
        padding: 32px;
    }
}
