.track-order-page {
    display: flex;
    flex-direction: column;
    background: var(--background-primary-color);
    padding: 1rem;
}

.track-order-page-container {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    padding: 64px 80px 64px 80px;
    gap: 32px;
    flex: 1;
    box-shadow: 2px 2px #f0f2f5;
}

.track-order-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.login-form-container {
    width: 100%;
    margin: 0 auto;
}

.vertical-line-container {
    margin: 0px 60px
}

.vertical-line {
    width: 3px;
    background-color: black;
    height: 145px;
    margin: 0 auto;
  }

  .vertical-line-text {
    position: relative;
    text-align: center;
    font-size: 20px;
    color: #101828;
    font-weight: 800;
    margin: 20px 0;
  }

  .need-help-container {
      margin: 3rem auto auto;
  }

  .need-help-text {
    font-size: 20px;
    color: #101828;
    font-weight: 600;
    padding-top: 8px;
  }

  .need-help-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: var(--tracking-need-help-icon-border);
    background-color: var(--tracking-need-help-icon-background);
    margin-right: 10px;
  }

  .need-help-icon {
    font-size: 20px;
    color: var(--tracking-need-help-color);
  }

  .need-help-phone-link {
    color: var(--tracking-need-help-color);
    text-decoration: underline;
  }

  .order-tracking-info-container {
    background-color: #E3E8ED;
    padding: 64px 80px 64px 80px;
    box-shadow: 2px 2px #f0f2f5;
  }

  .order-tracking-info-list { 
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  @media only screen and (max-width: 900px) {
    
    .track-order-page-container {
        background: #ffffff;
        padding: 32px;
        gap: 32px;
        flex: 1;
        box-shadow: 2px 2px #f0f2f5;
    }

    .track-order-content {
        flex-direction: column;
        gap: 36px
    }
    .vertical-line-container {
        margin: 0;
    }
    .vertical-line {
        display: none;
    }

    .vertical-line-text {
        text-align: left;
        margin: 0;
        font-size: 25px;
    }

    .order-tracking-info-container {
        padding: 64px 50px;
    }
  }