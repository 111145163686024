
.input-select-image-and-text-container {
  margin: 1rem 0;
  width: 100%;
}

.product-input-view-label {
  color: var(--darker-primary-color);
}

.component-option-container {
  display: flex;
  gap: 0.5rem;
}

.component-option-content {
  border: 2px solid #D0D5DD;
  border-radius: 4px;
  cursor: pointer;
  height: 133px;
  width: calc(50% - 0.25rem);
  display: grid;
  grid-template-rows: calc(107px - 2.5rem) 25px;
  align-items: center;
  justify-items: center;
  gap: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.selected-image-and-text {
  background-color: var(--category-header-background);
  border: 2px solid #1D2939;
}

.duplicates-svg, .single-svg rect{
  fill: #F2F4F7;
  stroke: #475467;
  stroke-width: 2px;
}

.check-input-option-container svg{
  height: 100%;
  width: 100%;
}

.check-input-option-container{
  max-width: 80%;
  height: 100%;
}

.check-input-option-hide {
  display: none;
}