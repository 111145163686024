/* Protection Selector. */

.protection-col {
	-webkit-box-shadow: inset 0 0 0 1px #cccccc;
	-moz-box-shadow: inset 0 0 0 1px #cccccc;
	box-shadow: inset 0 0 0 1px #cccccc;
}

.protection-option-table-body {
	-webkit-box-shadow: inset 0 -1px 0 0 #cccccc;
	-moz-box-shadow: inset 0 -1px 0 0 #cccccc;
	box-shadow: inset 0 -1px 0 0 #cccccc;
	cursor: pointer;
}

.protection-option-table-foot {
	cursor: pointer;
}

.protection-option-col {
	-webkit-box-shadow: inset 0 0 0 1px #cccccc;
	-moz-box-shadow: inset 0 0 0 1px #cccccc;
	box-shadow: inset 0 0 0 1px #cccccc;
}

.protection-option-col:hover {
	-webkit-box-shadow: inset 0 0 0 2px #0a4e8a;
	-moz-box-shadow: inset 0 0 0 2px #0a4e8a;
	box-shadow: inset 0 0 0 2px #0a4e8a;
}

.protection-option-col-checked {
	-webkit-box-shadow: inset 0 0 0 2px #0a4e8a;
	-moz-box-shadow: inset 0 0 0 2px #0a4e8a;
	box-shadow: inset 0 0 0 2px #0a4e8a;
	background-color: #b6effb;
}

.protection-option-label {
	color: #0a4e8a;
}

/* Mobile. */

.protection-option-mobile {
	-webkit-box-shadow: inset 0 0 0 1px #cccccc;
	-moz-box-shadow: inset 0 0 0 1px #cccccc;
	box-shadow: inset 0 0 0 1px #cccccc;
	margin: 4px 0 4px 0;
	padding: 1.2rem;
	cursor: pointer;
}

.protection-option-mobile input {
	margin: 4px 8px 0 4px;
}

.protection-option-mobile:hover {
	-webkit-box-shadow: inset 0 0 0 2px #0a4e8a;
	-moz-box-shadow: inset 0 0 0 2px #0a4e8a;
	box-shadow: inset 0 0 0 2px #0a4e8a;
}

.protection-option-mobile-checked {
	-webkit-box-shadow: inset 0 0 0 2px #0a4e8a;
	-moz-box-shadow: inset 0 0 0 2px #0a4e8a;
	box-shadow: inset 0 0 0 2px #0a4e8a;
	background-color: #b6effb;
}

.protection-benefit-mobile {
	margin: 8px 0 8px 30px;
}

.protection-icon-mobile {
	margin-right: 2px;
}
