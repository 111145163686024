
/* 0 height and width instead of display none so that the image element does fetch the src */
.hidden-image {
    height: 0 !important;
    width: 0;
}

.display-image-loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}