
.product-search-form {
	display: inline-flex;
	margin: 0.3rem 0.3rem 0.3rem 0.7rem;
	min-width: 300px;
}

.product-search-form-input {
	margin-right: 0.3rem;
}
