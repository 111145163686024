
.radio-select-with-button-single-select-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1rem;
}

.radio-option-input-container {
    margin: 0.5rem 1rem 0.5rem 0;
}

.radio-option-input {
    cursor: pointer;
    margin-right: 0.5rem;
}

.radio-option-input-label {
    cursor: pointer;
}

.radio-button-select-button-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}
.radio-button-select-button-container > .accordion{
    width: 100%;
}

.radio-select-with-button-single-select-input-label {
    font-weight: var(--h5-font-weight) !important;
    font-size: var(--h5-font-size) !important; 
}

.radio-select-with-button-single-select-option-container {
    width: 100%;
    padding: 0.2em;
}
    .radio-select-with-button-single-select-option-container.is-invalid {
        border: 1px solid #dc3545;
        border-radius: 4px;
    }

@media (max-width: 768px) {
    .radio-select-with-button-single-select-option-container {
        display: flex;
        flex-flow: column;
    }
}

.radio-select-with-button-single-select-alert-container {
    margin-top: 1rem;
    margin-bottom: 0px;
}

.radio-button-selected-button-option {
    background-color: white !important;
    border: 1px solid #D0D5DD !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    color: black;
    flex: 0 1 0 !important;
}

.radio-button-selected-button-option.active {
    background-color: var(--light-gray) !important;
    border: 4px solid var(--primary-color) !important; 
}

.radio-select-with-button-single-select-option-group-container {
    display: flex;
    flex-flow: column;
}

.radio-select-with-button-single-select-option-button-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
    gap: 8px;
}

.accordion h5{
    margin: 0;
}

.radio-select-with-button-single-select-input-option-image-container {
    padding: 5px !important; 
}

.radio-select-with-button-single-select-input-option-image-container img {
    width: 100%;
}

.radio-select-with-button-single-select-input-read-only-value img {
    max-width: 150px;
}

.image-selection-preview {
    margin-top: 10px;
}

.image-selection-preview img{
    width: 100px; 
    border: 1px solid grey; 
    border-radius: var(--btn-primary-border-radius);
}

.woodcut-accordion {
    width: 100%; 
}