/* Symbol Selector Accordion. */

.symbol-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
	gap: 8px;
}

.symbol-button {
	background-color: white !important;
	border: 2px solid #dddddd !important;
	box-shadow: 2px 2px 2px rgba(20, 20, 20, 0.05);
	border-radius: 4px;
	flex: 0 1 0 !important;
}

.symbol-button:hover {
	background-color: #b6effb !important;
}

.symbol-button img {
	width: 100%;
}
