
.banner-container {
  align-items: center;
  background-color: var(--banner-background);
  color: var(--banner-color);
  display: flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  padding: 4px 10px;
  font-size: 14px;
}

/* Mobile screen size */
@media only screen and (max-width: 821px) {
  .banner-container {
    width: 100%
  }
}

/* Desktop screen size */
@media only screen and (min-width: 821px) {
  .banner-container {
    border-radius: 4rem;
  }
}

.banner-icon-circle {
  align-items: center;
  background: var(--banner-icon-background);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-right: 4px;
  height: 28px;
  width: 28px;
  padding: 10px; 
}