
.product-search-product-image-container {
    text-align: center;
    width: 250px;
}

.product-search-product-image-container img {
    height: 100px;
    max-width: 250px;
}

.product-search-product-name-container {
    width: 50%;
}