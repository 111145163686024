.track-form-content {
    width: 100%;
    margin: 0 auto;
}

.track-form-title {
    font-size: 32px;
    line-height: 40px;
    color: #101828;
    margin-bottom: 2rem;
}