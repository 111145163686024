
.refund-approve-deny-container {
    margin: 1rem;
}

.refund-approve-deny-button-container {
    display: flex;
    flex-flow: row;
    justify-content: end;
    width: 100%;
}

.refund-approve-deny-button-container button {
    margin-right: 1rem;
}

.refund-and-recharge-label {
    padding-left: 1rem;
}

.refund-and-recharge-label:hover {
    cursor: pointer;
}

.refund-and-recharge-form-group {
    align-items: baseline;
    display: flex;
}