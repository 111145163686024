
.product-preview-container {
  display: flex;
}

.product-preview-container a {
  color: black;
  text-decoration: none;
  width: 100%;
}

.product-preview-container a:hover {
  color: black;
  text-decoration: none;
}

.product-preview-image img:hover{
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
}

.product-preview-image img {
  max-width: 100%;
  padding: 4px;
}

.product-preview-image .hidden-image{
  height: 150px !important;
}

.product-preview-card {
  border: none;
  height: 100%;
}

.product-preview-category {
  color: #475467;
  margin-bottom: 0; 
}

.product-preview-card > .card-body {
  padding: unset;
  padding-top: 16px;
}

.product-preview-name{
  color: #1D2939;
  font-weight: bold; 
}

.carousel-root .product-preview-image{
  height: 100%;
}