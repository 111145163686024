/* Homepage header main banner. */

.header-banner-main {
	position: relative;
	height: 400px;
	overflow: hidden;
}

.header-banner-main img {
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
}

.header-banner-text-1 {
	font-family: "Noto Sans Bold", sans-serif;
	color: var(--primary-color) !important;
	font-weight: 900 !important;
	font-size: 1.2em;
}

.header-banner-text-2 {
	font-family: "Noto Sans Bold", sans-serif;
	font-weight: 900;
	font-size: 2em;
}

.header-banner-text-3 {
	font-size: 1.15em;
}

.header-banner-overlay {
	position: absolute;
	width: 45%;
	top: 0;
	bottom: 0;
	background: var(--hero-text-background);
	border-radius: 0 0 100% 0;
	margin-bottom: -10%;
	padding: 30px 100px 40px 60px;
	color: white;
}

.header-banner-button-row {
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: center;
}

.header-banner-button-1 {
	border: 2px solid white !important;
	background-color: var(--primary-color) !important;
	margin: 0 3px 8px 3px;
}

.header-banner-button-2 {
	border: 2px solid white !important;
	background-color: var(--primary-medium) !important;
	margin: 0 3px 8px 3px;
}

.header-sub-banner-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	margin-top: -90px;
	justify-content: center;
	width: 100%;
}

/* ================================================ */
/* Media breakpoints (based on Bootstrap defaults). */
/* ================================================ */

@media only screen and (max-width: 1400px) {
	.header-banner-overlay {
		width: 55%;
	}
}

@media only screen and (max-width: 1200px) {
	.header-banner-overlay {
		width: 65%;
	}

	.header-banner-text-2 {
		font-size: 1.5em;
	}

	.header-banner-text-3 {
		font-size: 1em;
	}
}

/* Significant layout changes occur at this breakpoint. */
@media only screen and (max-width: 992px) {
	.header-banner-overlay {
		width: 100%;
		bottom: 170px;
		border-radius: 0 0 100% 100%;
		margin-bottom: 0;
		padding: 20px 50px 30px 50px;
		text-align: center;
	}

	.header-banner-text-1 {
		display: none;
	}

	.header-banner-text-2 {
		font-size: 1.4em;
	}
}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 576px) {
	.header-banner-main {
		height: 260px;
	}

	.header-banner-overlay {
		bottom: 0;
		border-radius: 0 0 0 0;
	}
	
	.header-banner-text-3 {
		display: none;
	}

	.header-sub-banner-container {
		margin-top: -80px;
	}
}
