
.loading-spinner-container {
    display: flex;
    justify-content: center;
    margin: 2rem;
    background: transparent;
}

.loading-spinner-container-no-margin {
    display: flex;
    justify-content: center;
    margin: 0;
    background: transparent;
}