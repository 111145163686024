
.cart-review-container {
    align-items: center;
    background: #F2F4F7;
    display: flex;
    flex-flow: column;
}

.cart-review-header-container {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}

@media (max-width: 768px) {
    .cart-view-header-group {
        display: flex;
        flex-direction: column;
    }
}

.cart-review-title {
    font-size: 175%;
    font-weight: bold;
    margin-right: 10px;
}

.cart-review-item-count {
    color: #667085;
}

.cart-review-content-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10%;
}

.cart-review-proceed-button {
    margin-top: 5%;
}

@media (max-width: 768px) {
    .cart-review-content-container {
        flex-direction: column;
    }
    .cart-review-lane-one {
        width: 100%;
    }
    .cart-review-lane-two {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .cart-review-lane-two a {
        width: 100%;
    }
    .cart-review-proceed-button {
        width: 100%;
    }
}

@media (min-width: 769px) {

    .cart-review-lane-one {
        width: 65%;
    }
    .cart-review-lane-two {
        width: 30%;
    }
    .cart-review-proceed-button {
        margin-top: 5%;
        width: 100%;
    }
    .sticky-sidebar-content{
        padding: 16px 0;
    }
    .remove-all-button{
        margin-bottom: 16px;
    }
    .remove-all-button > .btn{
        height: 42px !important;
    }
}

.cart-review-proceed-button:disabled {
    background-color: var(--primary-color);
    opacity: 0.5;
}

.cart-review-shipping-disclaimer {
    font-style: italic;
    margin-top: 5%;
    text-align: center;
}