
.input-text-radio-select-container {
  margin: 0 0 1rem 0;
  width: 100%;
}

.radio-button-group {
  display: flex;
  flex-direction: column;
}

.radio-button-label {
  align-items: center;
  border: 1px solid #D0D5DD;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
}

.radio-button-label.checked {
  background-color: var(--security-background);
  border: 2px solid var(--cart-overview-discount);
}

.radio-button-label:hover {
  background-color: #ced4da;
}

.radio-select-button {
  margin: 0.8rem;
  min-width: 16px;
}

.radio-button-text {
  flex-grow: 2;
}

.radio-button-price {
  margin: 0 0.4rem;
  text-align: end;
}

.radio-button-checks-per-pack {
  margin: 0 0.4rem;
  text-align: end;
  color: #667085;
  font-size: 14px;
}

.info-badge {
  background-color: #FFE29E;
  color: black;
}