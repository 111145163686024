
.input-checkbox-container {
    margin-bottom: 1.5rem;
    width: 100%;
}

.input-checkbox-label {
    color: var(--cart-overview-discount);
    font-weight: 500;
    margin-bottom: inherit;
}

.checkbox-input-helper-text {
    display: block;
}

.checkbox-input-edit-mode-container {
    display: grid;
    grid-template-columns: 1rem 1fr;
    gap: 1rem;
}