

.button-revealing-text-input-container {
    margin-bottom: 1.5rem;
    width: 100%;
}

.button-to-reveal-text-input {
    background: white;
    border: 1px solid #D0D5DD;
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    color: black;
    width: 100%;
}

.button-to-reveal-text-input-user-input-container {
    display: flex;
    position: relative;
    justify-content: space-between;
}

.remove-button-revealed-text-input-button {
    margin-left: 2rem;
}

.button-revealing-text-input-label {
    font-weight: bold;
}

.input-and-feedback-container {
    display: flex;
    flex-flow: column;
    width: 100%;
}