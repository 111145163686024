.mobile-navigation-menu {
    max-height: 100vh;
}
.mobile-navigation-menu .accordion-body {
    padding: 0;
    background-color: var(--nav-children-container-background);
    color: var(--darker-primary-color);
}

.mobile-navigation-menu .accordion-item {
    border: none;
}

.mobile-navigation-menu button.accordion-button, .mobile-navigation-menu .mobile-nav-display-name {
    padding: 1rem 1.25rem;
    font-size: var(--h6-font-size);
    font-family: var(--h6-font-family); 
    font-weight: var(--h6-font-weight); 
}

.mobile-navigation-menu button.accordion-button {
    border-radius: 0 !important;
}
    .mobile-navigation-menu button.accordion-button:focus {
        box-shadow: none !important;
    }
    .mobile-navigation-menu button.accordion-button:not(.collapsed) {
        color: var(--darker-primary-color);
        background-color: #FFFFFF;
    }
        .mobile-navigation-menu button.accordion-button:not(.collapsed)::after {
            background-image: var(--bs-accordion-btn-icon);
        }

.mobile-navigation-menu svg.icon {
    width: 20px;
    height: 18px;
    margin-right: 8px;
}