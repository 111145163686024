
@media (max-width: 768px) {

    .order-product-preview-container {
        flex-direction: column;
    }
}
@media (min-width: 768px) {

    .order-product-preview-container {
        align-items: center;
    }
}

.order-product-preview-container {
    display: flex;
    overflow-y: hidden;
}

.order-product-preview-image-container {
    margin-right: 1rem;
    max-height: 500px;
    max-width: 200px;
}

.order-product-preview-image-container img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}