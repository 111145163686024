.sign-up-page {
    background: var(--background-primary-color);
    padding: 1rem;
}

.sign-up-page-container {
    background: linear-gradient(90deg, #E4E7EA 33%, #FFFFFF 0%);
    padding: 3rem;
    gap: 32px;
    flex: 1;
    box-shadow: 2px 2px #f0f2f5;
}

.sign-up-content {
    flex-direction: row;
}

.sign-up-title {
    font-size: 32px;
    line-height: 40px;
    color: #101828;
}

.sign-up-body .sign-up-text {
    gap: 20px;
}

.sign-up-placeholer-title {
    font-weight: 600;
    font-size: 17px;
    letter-spacing: -0.03em;
    color: #101828;
}

.sign-up-text {
    width:33%;
    padding-right: 50px;
}

.password-input-group {
    position: relative;
}

.password-show-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

#confirm-password, #password-input {
    background-image: none;
}

.sign-up-form {
    max-width: 600px;
    width: 60%;
}

@media only screen and (max-width: 768px) {
    .sign-up-page {
        padding: 1rem;
    }

    .sign-up-form {
        width: 100%;
    }
    
    .sign-up-page-container {
        background: #ffffff;
        padding: 3rem;
        gap: 32px;
        margin: auto;
        flex: 1;
        box-shadow: 2px 2px #f0f2f5;
    }

    .sign-up-container {
        background: #ffffff;
    }

    .sign-up-content {
        flex-direction: column;
        gap: 36px
    }

    .sign-up-text-section {
        flex-direction: row;
        gap: 20px;
    }

    .sign-up-text {
        height: 0;
        transform: scale(0);
    }
}