
.edit-payment-option-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.edit-payment-option {
    align-items: center;
    display: flex;
    margin: 0.5rem 0;
}

.edit-payment-option-label {
    margin: 0 1rem;
}

.edit-payment-container {
    width: 100%;
}

.saved-payment-browse-edit-container {
    display: flex;
    width: 100%;
}