
.micr-edit-action-button-container {
    display: flex;
}
.micr-edit-action-button-container button {
    margin-left: 10px;
}

.micr-edit-input-column {
    align-items: center;
    display: flex;
}

.micr-edit-input-label {
    font-size: 1.10rem;
    font-weight: 500;
    margin: 0;
}

.micr-edit-input {
    margin: 0 15px;
    width: 150px;
}

.micr-edit-template-edit {
    margin-top: 5rem;
    margin-bottom: 1rem;
}

.show-tooltip {
    display: block;
}

.micr-edit-input-matches-on-selector-container {
    margin: 1rem 0;
}

.micr-edit-input-matches-on-selector-label {
    margin-left: 1rem;
}

.micr-edit-financial-instutition-name {
    font-size: 140%;
    font-weight: bold;
}

.financial-institution-select-menu {
    display: flex;
    flex-flow: column;
}

.micr-edit-form-feedback {
    position: relative;
}

.micr-edit-history-description {
    white-space: break-spaces;
}

.financial-institution-select-menu div div {
    width: unset !important;
}