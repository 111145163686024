
.safety-and-security-section {
	align-items: center;
	padding-top: 32px;
	padding-bottom: 32px;
	gap: 32px;
}

.safety-and-security-background{
	background: var(--security-background);
}

.safety-and-security-title-container {
	align-items: center;
}

.safety-and-security-title-content {
	align-items: center;
	gap: 16px;
}

.safety-and-security-title {
	line-height: 32px;
	text-align: center;
	letter-spacing: -0.02em;
	color: var(--security-title-color);
}

.safety-and-security-items-container {
	align-items: flex-start;
	gap: 32px 32px;
	flex-direction: row !important;
	flex-wrap: wrap;
	justify-content: space-around;
}

.safety-and-security-item {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 30%;
	gap: 20px;
	min-width: 300px;
}

.safety-and-security-item-text-container {
	gap: 8px;
}

.safety-and-security-item-title {
	line-height: 30px;
	text-align: center;
	color: var(--security-title-color);
}

.security-item-icon {
	filter: var(--security-item-icon-filter);
}

.safety-and-security-item-content {
	line-height: 24px;
	text-align: center;
	color: var(--security-item-color);
}

/* Mobile screen size */
@media only screen and (max-width: 768px) {
	.safety-and-security-items-container {
		flex-direction: column !important;
		align-items: center;
		padding: 0;
		gap: 32px;
	}

	.safety-and-security-item {
		width: auto;
	}

	.safety-and-security-section {
		padding-top: 32px;
		padding-bottom: 32px;
		gap: 48px;
	}
}
