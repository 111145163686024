.tracking-details-no-orders {
    margin: auto;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: black;
    display: block;
    padding: 100px;
}

.tracking-details-container {
    margin: 2rem 0 4rem 0;
}