.trust-banner {
    margin-top: 1.8rem;
    padding: 0;
    text-align: center;
    align-content: center;
    left: calc(-50vw + 50%);
}

.trust-banner-large {
    margin-top: 0;
    position: relative;
    width: 100vw;
    background: var(--gray-ribbon-color);
}

.trust-banner img {
    max-height: 50px;
}

.trust-banner-large img {
    max-height: 75px;
}

.trust-banner .trust-banner-item {
    display: inline-block;
    vertical-align: middle;
    margin: 15px;
}

@media (min-width: 768px) {
    .trust-banner .trust-banner-item {
        margin: 5px 15px 5px 15px;
    }
}

.trust-banner-large .trust-banner-item {
    margin: 35px;
}

.trust-banner .thirty-percent-more-badge {
    margin: 0;
    display: inline-block;
    color: var(--thirty-percent-more-badge-outline);
    border: 4px solid var(--thirty-percent-more-badge-outline);
    border-radius: 10px;
    background: var(--thirty-percent-more-badge-contents);
    min-width: 150px;
    font-size: 12px;
    line-height: 1.7;
    font-family: Arial, sans-serif;
}

.trust-banner-large .thirty-percent-more-badge {
    min-width: 210px;
    font-size: 16px;
}

.trust-banner .thirty-percent-more-badge header {
    display: block;
    background: var(--thirty-percent-more-badge-outline);
    color: var(--thirty-percent-more-badge-contents);
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    padding: 6px 0 10px;
    letter-spacing: 1px;
    font-family: Arial, sans-serif;
}

.trust-banner-large .thirty-percent-more-badge header {
    font-size: 22px;
}

.thirty-percent-more-badge.small {
    min-width: 190px;
    font-size: 14px;
    border-radius: 8px;
}

.thirty-percent-more-badge.small header {
    font-size: 20px;
    padding: 5px 0 9px;
}