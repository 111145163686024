.printing-software-input-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    width: 100%;
}

.printing-software-input-container span {
    margin-bottom: 0.5rem;
}

.printing-input-label {
    font-weight: bold;
}

.printing-input-group {
    justify-content: space-between;
    width: 100%;
}

.invalid-printing-input {
    border: 2px solid red;
}