
.financial-institution-details-card {
    margin-bottom: 2rem;
}

.financial-institution-details-input-container {
    align-items: center;
    display: flex;
}

.financial-institution-details-input-label-left {
    margin: 0;
    min-width: 200px;
    padding-right: 1rem;
}

.financial-institution-details-input-label-right {
    margin: 0;
    min-width: 130px;
    padding-right: 1rem;
}

.financial-institution-details-input-button {
    margin-left: 1rem;
}

.financial-details-micr-browse-container {
    max-width: 100%;
    /*overflow-x: scroll;*/
    margin-bottom: 200px;
}

.financial-institution-details-form-feedback {
    margin-left: 1rem;
    position: relative;
    white-space: nowrap;
}

.financial-institution-details-history-description {
    white-space: break-spaces;
}