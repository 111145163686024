.contact-page {
    background: var(--background-primary-color);
}

.contact-us-container {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 32px));
    grid-template-rows: auto 1fr;
    background: linear-gradient(90deg, #E4E7EA 50%, #FFFFFF 50%);
    padding: 64px 32px;
    gap: 64px;
    box-shadow: 2px 2px #f0f2f5;
    margin-bottom: 64px;
}

.contact-title {
    grid-row: 1/2;
    grid-column: 1/2;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #101828;
}

.contact-text {
    gap: 20px;
    grid-row: 2/3;
    grid-column: 1/2;
    padding-right: 50px;
}

.contact-checkworks {
    font-weight: 600;
    font-size: 17px;
    letter-spacing: -0.03em;
    color: #101828;
}

.contact-us-form-section-title {
    font-weight: 600;
    font-size: 17px;
    letter-spacing: -0.03em;
    color: #101828;
}

.contact-us-link {
    color: #2C88D9;
    text-decoration: underline; 
 }

.contact-us-text-section {
    flex-direction: column;
    gap: 20px;
 }

@media (max-width: 768px) {

    .contact-us-container {
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 36px
    }

    .contact-us-text-section {
        flex-direction: row;
        gap: 20px;
    }

    .contact-text {
        width: 100%;
        text-align: center;
        padding-right: 0;
    }
}