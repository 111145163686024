
.breadcrumbs-container {
    display: flex;
    padding: 0;
    /* font-size: 14px !important; */
    margin: 32px 0 32px 0;
}

a {
    text-decoration: none;
    color: inherit;
}

.breadcrumbs-container span {
    margin-right: 1rem;
}

@media (max-width: 768px) {
    .breadcrumbs-parent-link-container {
        max-width: 100%;
    }

    .breadcrumbs-non-active-link {
        max-width: 25%;
    }
    .breadcrumbs-container {
        margin: 24px 0 24px 0;
    }

}

.breadcrumbs-parent-link-container {
    display: flex;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.breadcrumbs-non-active-link {
    color: var(--breadcrumb-inactive-color);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.breadcrumbs-non-active-link:hover {
    color: var( --footer-links-color-shadow); 
    text-decoration: underline;
}

.breadcrumbs-separator-slash {
    color: var(--breadcrumb-separator-color);

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.breadcrumbs-active {
    color: var(--breadcrumb-active-color);

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.breadcrumbs-parent-category {
    color: var(--breadcrumb-parent-color);

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.breadcrumb-spacer {
    height: 5rem;
}
