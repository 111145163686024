/* Checkout Form. */

/*
TODO:
 Every css file is loaded for every page for some unknown reason, so names have to be longer
 so that they don't conflict. When that is fixed, this can be part of a shared style.
*/

.checkout-boxed-radio-option {
	border: 1px solid #999999;
	border-radius: 6px;
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}

.checkout-boxed-radio-option:hover {
	border: 1px solid #0A4E8A;
}

.checkout-boxed-radio-option label {
	margin: 12px;
	display: flex;
	align-items: center;
	width: 100%;
}

.checkout-boxed-radio-option span {
	margin-left: 12px;
	min-width: 150px;
}

/* Applied via code when checked. */
.checkout-boxed-radio-option-checked {
	border: 2px solid #0A4E8A !important;
	background-color: #b6effb;
}
