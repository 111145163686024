
.consumer-control-list-value-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-flow: row-reverse;
    padding-top: 1rem;
}

.consumer-control-list-value-header button {
    max-height: 50px;
}

.consumer-control-list-value-no-matches {
    font-size: 105%;
    margin: 2rem 0;
}

.consumer-control-list-value-row:hover {
    cursor: pointer;
}

.consumer-control-list-value-spinner-container {
    display: flex;
    justify-content: center;
    width: 100%;
}