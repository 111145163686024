.contact-container {
    gap: 32px;
    width: 100%; 
}

.footer-logo {
    color: var(--contact-footer-logo-color);
    font-size: 30px;
    line-height: 24px;
    font-style: normal;
    font-weight: 700;
}

.contact-supporting-text {
    gap: 16px;
}

.supporting-text {
    line-height: 24px;
    color: var(--contact-footer-text-color);
}

.contact-button {
    box-sizing: border-box;
    background: var(--contact-footer-btn-background) !important;
    border-radius: 10px !important;
    justify-content: center;
    align-items: center;
    padding: 10px 10px !important;
    gap: 6px !important;
    color: var(--contact-footer-btn-color) !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    transition: 0.6s;
    border: var(--contact-footer-btn-border) !important; 
}

.contact-button h6, .contact-button h6:hover {
    text-decoration: none !important;
}

.contact-button:hover {
    background-color: var(--contact-footer-btn-color) !important; 
    color: var(--contact-footer-btn-background) !important;
    border: var(--contact-footer-btn-border-hover) !important;
    text-decoration: none !important;
}

#phone-number-text, #phone-number-text:hover {
    text-decoration: none !important; 
}


.contact-icon {
    filter: var(--contact-footer-icon-filter);
}

.title-logo.mobile img {
    height: 40px; 
}