
.user-action-bar-container {
	align-items: center;
	background: var(--user-action-bar-background);
	color: var(--user-action-bar-color);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.user-action-container {
	align-items: center;
	cursor: pointer;
}

.user-action-container:hover, .user-action-text:hover, .signInButton:hover {
	color: var(--user-action-hover) !important;
}

.user-action-link {
	padding: 0.4rem 0rem 0.4rem 2rem;
}

/* Mobile screen size */
@media only screen and (max-width: 768px) {
	.banner-view-container {
		width: 100%;
	}

	.user-action-link {
		padding: 0.4rem 1rem;
	}

	.user-action-spacer {
		display: none;
	}

	.reorder-checks-action-link {
		display: none;
	}
}

/* Desktop screen size */
@media only screen and (min-width: 769px) {
	.user-action-bar-container {
		padding: 0 2%;
	}

	.banner-view-container {
		padding: 0.4rem;
	}

	.user-action-container {
		display: flex;
	}
}

.user-action-bar-icon {
	filter: var(--user-action-bar-icon-filter);
}

.user-action-text {
	margin-left: 0.5rem;
	color: var(--user-action-bar-color);
}

a.signInButton {
	text-decoration: none;
	color: var(--user-action-bar-color);
}
