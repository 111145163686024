.faq-page {
    background: var(--background-primary-color);
}

.faq-container {
    background: #ffffff;
    padding: 50px;
    gap: 32px;
    flex: 1;
    box-shadow: 2px 2px #f0f2f5;
    margin-bottom: 64px;
}

.faq-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #101828;
}

.faq-body {
    gap: 20px;
}

.faq-nav {
    gap: 30px;

    padding-bottom: 10px;
}

.faq-nav-link {
    color: #101828;
    padding: 0 0 10px 0;
}

.faq-nav-link:hover {
    cursor: pointer;
}

.faq-nav-link:hover, .faq-nav-link-active {
    color: #293845;
    padding: 0 0 7px 0;
}

.faq-nav-item:hover, .faq-nav-item-active {
    border-bottom: 3px solid #101828;
}

ul.faq-nav.nav.nav-tabs {
    --bs-nav-tabs-border-width: none !important;
}
@media(max-width: 768px){
    .faq-container{
        padding: 32px 20px 32px 20px;
    }
}
.accordion-body > span > span {
    overflow-wrap: break-word;
    margin-bottom: .5rem;
}

.accordion-body ol, ul {
    margin-bottom: unset;
}
