
.numeric-input-label {
    font-weight: bold;
}

.numeric-input-edit-mode-container {
    display: flex;
    position: relative;
}

.invalid-numeric-input {
    border: 2px solid red;
}

.invalid-numeric-input:focus {
    outline: none !important;
    border:2px solid red;
    box-shadow: 0 0 10px #ce717c;
}