.contact-us-form-label {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.03em;
    color: #101828;
}

.contact-us-button-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.contact-form-submit-text {
    padding-left: 4px;
}

.contact-form {
    grid-row: 2/3;
    grid-column: 2/3;
}

.contact-us-required-toast div.required-fields-toast {
    left: 0;
    right: 0;
    margin: auto;
}

.contact-us-modal-header {
    padding-left: 32px;
    padding-right: 32px;
}
.contact-us-modal-body{
    padding: 32px;
}
.contact-us-modal-phone-number{
    white-space: nowrap;
}
.modal-open{
    /*fixes issue where bootstrap adds padding to appliction body while the modal is open*/
    padding-right: 0px !important;
}

@media only screen and (max-width: 768px) {
    .contact-form {
        width: 100%;
    }
    
}