/* Homepage header subordinate banners. */

.header-sub-banner {
	border-radius: 10px;
	background-color: var(--gray-ribbon-color);
	border: 1px solid var(--light-gray);
	margin: 15px 15px 0 15px;
	width: 400px;
	height: auto;
	text-align: center;
	align-content: center;
	align-items: center;
	padding: 20px;
	color: var(--dark-primary-color);
}

.header-sub-banner-icon img {
	height: 60px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.header-sub-banner-text-1 {
	font-family: "Noto Sans Bold", sans-serif;
	font-weight: 900;
	font-size: 1.6em;
}

.header-sub-banner-text-2 {
	font-size: 1.2em;
}

.header-sub-banner-button {
	margin-top: 24px;
}

/* ================================================ */
/* Media breakpoints (based on Bootstrap defaults). */
/* ================================================ */

@media only screen and (max-width: 1400px) {
	.header-sub-banner {
		width: 350px;
	}

	.header-sub-banner-text-1 {
		font-size: 1.4em;
	}

	.header-sub-banner-text-2 {
		font-size: .9em;
	}
}

@media only screen and (max-width: 1200px) {
	.header-sub-banner {
		width: 275px;
		padding: 17px;
	}
}

/* Significant layout changes occur at this breakpoint. */
@media only screen and (max-width: 992px) {
	.header-sub-banner {
		width: 95%;
		height: auto;
		display: flex;
	}

	.header-sub-banner-icon {
		flex-grow: 1;
	}

	.header-sub-banner-text-group {
		flex-grow: 1;
		padding: 0 15px 0 15px;
	}

	.header-sub-banner-text-1 {
		font-size: 1.5em;
	}

	.header-sub-banner-text-2 {
		font-size: .9em;
	}

	.header-sub-banner-button {
		flex-grow: 1;
		margin-top: 0px;
	}
}

@media only screen and (max-width: 768px) {
	.header-sub-banner-icon img {
		height: 40px;
	}
}

@media only screen and (max-width: 576px) {
	.header-sub-banner-icon {
		display: none;
	}

	.header-sub-banner-icon img {
		display: none;
	}
}
