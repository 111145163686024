

.micr-preview-container {
    display: flex;
    margin: 1rem 0;
    flex-wrap: wrap;
}

.micr-preview-input-container {
    display: flex;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
}

.micr-preview-input-container label {
    min-width: 200px;
}

.micr-preview-text-container {
    display: flex;
}

.micr-preview-input-form-feedback {
    bottom: 110%;
    right: 0;
    top: unset;
}