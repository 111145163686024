
.consumer-detail-checkbox-input {
    margin-right: 0.5rem;
}


.consumer-detail-checkbox-label {
    padding-right: 1rem;
}

.consumer-detail-button {
    margin: 1rem;
}