
.footer-container {
    width: 100%;
}

.footer-contact-container {
    background: var(--footer-contact-background);
}

.footer-section {
    align-items: center;
    padding: 30px 0px 48px;
    align-self: stretch;
}

@media only screen and (max-width:768px) {
    .footer-section {
        padding: 30px 0px 80px; 
    }
}

.footer-section-container {
    gap: 48px;
    width: 100%;
}

.footer-section-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px,1fr));
    gap: 40px 30px;
    align-items: start;
}

@media only screen and (max-width: 768px) {
    .footer-section-container {
        gap: 20px 30px; 
    } 
}

.footer-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    grid-column: -2 / span 1;
    justify-self: end;
    align-self: end;
}

@media only screen and (max-width: 820px) {
    .footer-icon-container {
        display: none;  
    } 
}

.links-container{
    justify-self: end;
}

@media(max-width: 768px){
    .links-container, .footer-icon-container, .contact-container{
        justify-self: center;
    }
}