#back-to-top-container {
    width: 100vw;
    display: flex;
    justify-content: center;
}

#back-to-top-btn {
    z-index: 999;
    display: none;
    position: fixed;
    bottom: -60px;
    cursor: pointer;
    padding: 10px 20px;
    transition: background-color .3s;
    box-shadow: 0 0 3px white;
}

#back-to-top-btn p {
    margin-bottom: 0; 
}

@keyframes slideIn {
    from {
        transform: translateY(0%);
    }

    to {
        transform: translateY(-160%);
    }
}

@keyframes slideOut {
    from {
        transform: translateY(-160%);
    }

    to {
        transform: translateY(0%);
    }
}