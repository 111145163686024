.dropdown-menu .dropdown-item {
    margin: unset;
}

.rbt-highlight-text {
    background-color: unset;
    padding: unset;
    font-weight: 600;
}

.rbt-aux {
    position: relative;
    bottom: 30px;
    display: flex;
    justify-content: flex-end;
    margin: 0px 5px;
}

.rbt-close-content {
    display: none;
}