
.categoryViewContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.categoryItemsContainer {
    margin-top: 32px;
    margin-bottom: 64px;
}

.categoryFlavorText {
    line-height: 28px;
    color: var(--dark-gray);
}