.navigation-children-dropdown-menu {
    border-radius: 0;
    border: 0;
    padding: 0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.navigation-children-dropdown-item {
    padding: 12px 60px 12px 14px;
}

.navigation-children-dropdown-item:hover .navigation-child-item-name {
    text-decoration: underline;
}

.navigation-child-item-name {
    font-size: 18px;
    font-weight: 500;
}