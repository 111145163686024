
.user-orders-muted-text {
    display: flex;
    font-size: 90%;
    opacity: 0.6;
}

.user-orders-no-orders-text {
    padding: 1rem 0;
}

.user-orders-card {
    margin-top: 1rem;
}

.user-orders-card-header {
    display: flex;
    font-weight: bold;
    font-size: 125%;
    justify-content: space-between;
}

.user-orders-container {
    display: flex;
    flex-flow: column;
    padding: 0 1rem;
    width: 100%;
}