
.grid-table-container {
    overflow: scroll;
    width: 100%;
}

.grid-column-header-title-text {
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
}

.grid-row-is-clickable:hover {
    cursor: pointer;
}

.grid-table-td-display {
    white-space: pre;
}

.grid-table-container table th, .grid-table-container table td{
    border: 0.5px solid;
    border-color: inherit;
}
.grid-table-container table th{
    background-color: white;
}
.grid-table-container table thead{
    position: sticky;
    top: 0;
}
.grid-table-container table {
    border-collapse: separate;
    border-spacing: 0;
}