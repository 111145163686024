
.cart-overview-container {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0 2px 4px -2px rgba(16, 24, 40, 0.06);
    padding: 5% 0;
}

@media (max-width: 768px) {
    .cart-overview-container {
        width: 100%;
    }
}

.cart-overview-header {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    margin: 0 5%;
}

.cart-overview-discount {
    color: var(--cart-overview-discount);
    margin: 0 5%;
}

.cart-overview-subtotal {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 0 5% 12px 5%;
}

.cart-overview-discount-container {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 0 5%;
}

.cart-overview-discount-detail-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.cart-overview-discount-row {
    display: flex;
}

.cart-overview-discount-row input,
.cart-overview-discount-row button {
    margin: 0 5%;
}