.form-title {
    font-size: 32px;
    line-height: 40px;
    color: var(--sign-in-title-color);
    margin-bottom: 2rem;
}

.sign-in-forgot-password-link {
    text-decoration: none;
}

.password-input-group {
    position: relative;
}

.password-show-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

#password-input {
    background-image: none;
}

.forgot-password-btn-group {
    padding: unset;
    margin-top: 25px;
}
.forgot-password-btn-group > span {
    margin-right: 15px;
}

.forgot-password-toast, .sign-in-toast {
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 100px;
    width: 100%;
}

@media only screen and (max-width: 900px) {
    .form-page {
        padding: 1rem;
    }
    
    .form-page-container {
        background: #ffffff;
        padding: 64px 50px;
        gap: 32px;
        margin: auto;
        flex: 1;
        box-shadow: 2px 2px #f0f2f5;
    }

    .form-container {
        background: #ffffff;
    }
}