@media print {
    nav,
    .btn-primary,.btn-secondary,.btn-outline-primary,
    input,
    label,
    #CriteriaContainer {
        display: none !important;
    }
}

@media screen {
    .report-title {
        display: none !important;
    }
}