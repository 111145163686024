.contact-info-content {
    gap: 30px;
    flex-direction: row;
}
@media (max-width: 768px) {
    .contact-info-content {
        gap: 10px;
        flex-direction:  column;
    }
}

.contact-info-title {
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #101828;
}

.order-accordion-questions {
    gap: 15px;
    padding-bottom: 30px;
}

.order-accordion-order-question {
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #101828;
    font-size: 17px;
}

.order-accordion-answer-list-item-title {
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #101828;
    text-decoration: underline;
}