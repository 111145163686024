
.card-item-title {
    font-weight: 700;
}

.category-flavor-text {
    line-height: 28px;
    color: var(--dark-gray);
}

.aspect-ratio-2 img{
    aspect-ratio: 2/1;
    object-fit: contain;
}

.aspect-ratio-1 img{
    aspect-ratio: 1/1;
    object-fit: contain;
}

.see-more-button {
    margin-left: 1rem;
}