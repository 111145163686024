
.update-order-button-container {
    display: flex;
    flex-flow: row;
    justify-content: end;
    width: 100%;
}

.update-order-button-container button {
    margin-left: 1rem;
}

.update-order-error-message {
    color: red;
    padding: 2rem;
}

.update-order-success-message {
    padding: 2rem;
}