
.product-input-view-container {
  margin: 2rem 0;
}

.hide-input-in-review {
  display: none;
}

.invalid-tooltip {
  position: unset;
}