
.refund-tab-row {
    align-items: center;
    display: flex;
}

.refund-tab-row label {
    width: 40%;
}

.refund-tab-start-refund-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.refund-tab-start-refund-container .alert {
    margin-bottom: 0;
}