.privacy-policy-page {
    background: var(--background-primary-color);
}

.privacy-policy-container {
    background: #ffffff;
    padding: 50px;
    gap: 32px;
    flex: 1;
    box-shadow: 2px 2px #f0f2f5;
    margin-bottom: 64px;
}

.privacy-policy-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #101828;
}

.privacy-policy-body {
    gap: 20px;
}

.privacy-policy-body-content {
    gap: 20px;
}

.privacy-policy-content-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #101828;
}

.privacy-policy-content {
    gap: 18px;
}

.privacy-policy-information-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #101828;
}

.california-resident-subtitle {
    font-size: 18px;
    text-decoration: underline;
}

.privacy-policy-link {
    color: #2C88D9;
    text-decoration: underline;
}

.privacy-policy-social-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.privacy-policy-social-list-item {
    display: flex;
    flex-direction: column;
}

.privacy-policy-social-list-name {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #101828;
}
.privacy-policy-table-container{
    width: 100%;
    overflow-x: scroll;
}

@media (max-width: 950px ){/*prevents table from overflowing until very small screens */
    .privacy-policy-container {
        padding: 32px;
    }
}
.privacy-policy-body span{
    overflow-wrap: break-word;
}

.privacy-policy-sub-section-header {
    font-style: italic;
    margin-top: 20px;
}