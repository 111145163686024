.admin-error-boundary-page {
    background: var(--background-primary-color);
}

.admin-error-boundary-container {
    background: #ffffff;
    padding: 24px;
    gap: 32px;
    flex: 1;
    box-shadow: 2px 2px #f0f2f5;
    margin-bottom: 64px;
}

.admin-error-boundary-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #101828;
}

.admin-error-boundary-content-title {
    font-weight: 600;
    font-size: 38px;
    line-height: 35px;
    letter-spacing: -0.02em;
    color: #101828;
}

.admin-error-boundary-content-main-title {
    font-weight: 600;
    font-size: 36px;
    letter-spacing: -0.02em;
    color: #101828;
}

.admin-error-boundary-content-subtitle {
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -0.02em;
    color: #101828;
}

.admin-error-boundary-body {
    gap: 20px;
}