.welcome-section {
    background-color: var(--why-sitename-background-home);
}

.welcome-container {
    justify-content: center;
    align-items: center;
    padding-top: 96px;
}

.welcome-container p {
    color: var(--why-sitename-body-color);
    font-size: 18px;
}