.image-zoom-image-container {
    display: flex;
    justify-content: center;
    position: relative;
    width:90%;
    margin: 20px auto;
}

.image-zoom-image-container img {
    width: 100%;
}

.image-zoom-div {
    position: absolute;
    pointer-events: none;
    opacity: 1;
    border: 1px solid lightgray;
    background-color: #ffffff;
    background-repeat: no-repeat;
    height: 300px;
    width: 300px;
}

.product-preview-image {
    display: flex;
    position: relative;
    cursor: pointer;
}

.preview-image-zoom-option {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 56px;
    height: 56px;
    right: 25px;
    bottom: 25px;
    background: var(--primary-medium); 
    border-radius: 28px;
}

.preview-image-zoom-option-icon {
    color: #ffffff;
    font-size: 25px;
}

@media only screen and (max-width: 1200px) {
    .preview-image-zoom-option {
        width: 50px;
        height: 50px;
        right: 15px;
        bottom: 15px;
    }

    .preview-image-zoom-option-icon {
        font-size: 25px;
    }
}

@media only screen and (max-width: 900px) {
    .image-zoom-div {
        height: 150px;
        width: 150px;
    }
}