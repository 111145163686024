.user-addresses-container {
    display: flex;
    flex-flow: column;
    padding: 0 1rem;
    width: 100%;
    max-width: 762px;
}

.user-address-card {
    max-width: 750px;
}

.user-addresses-container>.card, .user-address-card:first-of-type {
    margin-top: 1rem;
}

.edit-btn {
    background: none!important;
    border: none;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

.edit-btn:hover {
    color: var(--dark-primary-color);
}

.street {
    font-weight: 700;
}

.city-state-zip {
    font-size: 20px;
    margin: 2px 0px 0px 0px;
}

.isDefaultAddress {
    color: var(--breadcrumb-inactive-color);
    font-weight: 500;
}

.edit-button {
    margin-bottom: 45px;
    text-decoration: underline;
}