
#AdminSite {
    margin-bottom: 0;
    height: calc(100vh - 55px);
    display: flex;
    flex-direction: column;
}

.admin-navbar-container {
    background: var(--primary-fill);
    color: #fff;   
}

.admin-navbar-nav {
    flex-wrap: wrap; 
}

.admin-navbar-nav .dropdown-menu .nav-link {
    color: var(--on-light);
}
.admin-main{
    height: 0;
    flex: 1;
}

.admin-nav-link {
    color: #fff;  
    padding-right: 25px !important; 
    padding-left: 0px !important; 
}

.admin-nav-link:hover {
    text-decoration:underline; 
}

.admin-brand {
    margin: 10px; 
    color: #fff; 
    font-family: Inter; 
}

.admin-brand:hover {
    color: rgba(255, 255, 255, 0.75); 
}

@media screen and (max-width: 768px) {
    .admin-brand{ 
        font-size: 30px; 
    }
}

.user-name {
    color: var(--dark-primary-color); 
}