/* Symbol Selector. */

.boxed-radio-option {
	border: 1px solid #999999;
	border-radius: 6px;
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}

.boxed-radio-option:hover {
	border: 1px solid #0A4E8A;
}

.boxed-radio-option label {
	margin: 12px;
	display: flex;
	align-items: center;
	width: 100%;
}

.boxed-radio-option span {
	margin-left: 12px;
	width: 40%;
	min-width: 150px;
}

.boxed-radio-option button {
	width: 100px;
	margin: 12px 8px 12px 8px;
}

@media (min-width: 576px) {
	.boxed-radio-option button {
		width: 170px;
	}
}

@media (min-width: 768px) {
	.boxed-radio-option button {
		width: 105px;
	}
}

@media (min-width: 992px) {
	.boxed-radio-option button {
		width: 170px;
	}
}

/* Applied via code when checked. */
.boxed-radio-option-checked {
	border: 2px solid #0A4E8A !important;
	background-color: #b6effb;
}

.symbol-selector-preview {
	min-height: 3rem;
}