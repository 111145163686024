
.product-details-outer-container {
  display: flex;
  justify-content: center;
}

.product-details-container {
  align-content: space-evenly;
  display: flex;
  width: 100%;
  margin-bottom: 64px;
}

.white-background {
  background-color: white;
}

@media (max-width: 768px) {
  .column-container.row-content{
    margin: 0;
  }

  .product-details-container {
    flex-direction: column;
  }

  .product-details-right-content {
    width: 100%;
  }

  .product-details-left-content {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .product-details-right-content {
    margin-left: 2%;
    width: 50%;
  }

  .product-details-left-content {
    margin-right: 2%;
    width: 50%;
  }
  .slide .product-preview-image{
    max-height: 50vh;
  }
}

.product-details-left-content {
  display: flex;
  flex-direction: column;
}

.product-details-right-content {
  display: flex;
  flex-direction: column;
}

.row-container {
  display: flex;
  justify-content: space-between;
}

.row-content {
  width: 100%;
}

.faded-label {
  color: var(--why-sitename-body-color);
  font-size: var(--p-font-size); 
  display: block;
}

.price-text {
  color: var(--banner-color);
  font-size: var(--h2-font-size); 
  font-weight: bold;
  text-align: right;
}

.pricing-label {
  text-align: right;
  font-weight: var(--h6-font-weight); 
  width: 80px; 
}


.price-text-mobile {
  font-size: var(--h2-font-size); 
  font-weight: bold;
  text-align: left;
}

.pricing-label-mobile {
  font-size: var(--h6-font-size); 
  font-weight: var(--h6-font-weight); 
  min-width: 80px;
  padding-top: 1rem;
  text-align: left;
}

.column-container {
  display: flex;
  flex-direction: column;
}

.column-container img {
  width: 100%;
}

.product-name {
  color: var(--banner-color);
  line-height: 41px;
  font-size: var(--h1-font-size); 
  font-weight: var(--h1-font-weight);
}

.product-image-container {
  display: block;
  overflow: hidden;
}
.carousel .thumb{
  padding: 0 !important;
}
.product-image-container .carousel .thumb:hover {
  border: 3px solid var(--primary-color);
}

.product-image-container .carousel .thumb.selected {
  border: 3px solid var(--primary-color);
  box-shadow: 0 0 3px 3px var(--light-primary-color);
}

@media (max-width: 768px) {
  .product-image-container {
    padding-top: 2rem;
  }

  .thumb .product-preview-image{
    margin: 0px;
    height: auto;
  }
  
  .slide .product-preview-image{
    height: 100%;
    max-height: 50vh;
    display: flex;
  }
}

.shipping-disclaimer-row {
  background-color: #FFF6E0;
  margin: 0.8rem 0 1.1rem 0;
  width: 100%;
  border: 1px solid var(--warning-color);
  border-radius: 6px;
}

.shipping-disclaimer {
  padding: .6rem;
  margin: unset;
  display: flex;
  align-items: center;
}

.light-circle {
  height: 61px;
  width: 61px;
  border-radius: 50px;
  background-color: var(--info-light);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-circle {
  height: 43px;
  width: 43px;
  border-radius: 50px;
  background-color: var(--info-dark);
  display: flex;
  justify-content: center;
  align-items: center;
}

.disclaimer-text {
  margin-left: 7px;
  color: var(--banner-color);
  font-weight: 500;
}

.customize-product-row {
  margin-top: 0.8rem;
}

.customize-product-row button {
  width: 100%;
}

.promotion-text {
  background: var(--promotion-background);
  border-radius: 16px;
  color: var(--promotion-color);
  font-size: 12px;
  padding: 0 0.4rem;
}

.product-detail-container {
  margin-top: 1rem !important;
  font-size: 18px;
  font-weight: 400;
  color: var(--banner-color);
}

.continue-button {
  height: 55px;
}

.required-fields-toast {
  background: #FBB7C3;
  cursor: pointer;
  position: fixed;
  bottom: 20%;
}


.required-fields-toast .toast-body {
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.pull-left {
  align-items: flex-start;
}

.carousel-navigation-controls {
  pointer-events: none;
  display: flex;
  align-items: center;
  height: 0px;
  justify-content: space-between;
  position: relative;
  bottom: 53px;
}

.carousel-navigation-arrow-button {
  pointer-events: auto;
  cursor: pointer;
}

.thumb {
  max-width: 100% !important;
  /*width: auto !important;*/
}

.carousel img {
  object-fit: contain;
}
.thumb img {
  aspect-ratio: 2200/1008;/*this is aspect ratio of personal check images*/
}

.thumbs-wrapper {
  height: 107px;
  margin: 24px 40px 0 40px !important;
  padding: 6px !important;
}

.thumbs-wrapper ul {
  padding: 0;
}

.control-arrow {
  display: none;
}

.product-bullet-point-item {
  margin-bottom: 0; 
}