@font-face {
	font-family: 'Noto Sans';
	src: url(./assets/fonts/NotoSans-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'Noto Sans Bold';
	src: url(./assets/fonts/NotoSans-Bold.ttf) format('truetype');
}
@font-face {
	font-family: 'Quicksand';
	src: url(./assets/fonts/Quicksand-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'Fira Sans';
	src: url(./assets/fonts/FiraSans-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'Oxygen';
	src: url(./assets/fonts/Oxygen-Regular.ttf) format('truetype');
}

html, body {
	font-family: 'Inter', sans-serif;
}

body h1 {
	font-family: var(--h1-font-family), sans-serif;
	font-size: var(--h1-font-size);
	font-weight: var(--h1-font-weight);
}

body h2 {
	font-family: var(--h2-font-family), sans-serif;
	font-size: var(--h2-font-size);
	font-weight: var(--h2-font-weight);
}

body h3 {
	font-family: var(--h3-font-family), sans-serif;
	font-size: var(--h3-font-size);
	font-weight: var(--h3-font-weight);
}

body h4 {
	font-family: var(--h4-font-family), sans-serif;
	font-size: var(--h4-font-size);
	font-weight: var(--h4-font-weight);
}

body h5 {
	font-family: var(--h5-font-family), sans-serif;
	font-size: var(--h5-font-size);
	font-weight: var(--h5-font-weight);
}

body h6 {
	font-family: var(--h6-font-family), sans-serif;
	font-size: var(--h6-font-size);
	font-weight: var(--h6-font-weight);
	margin-bottom: 0;
}

body p {
	font-family: var(--p-font-family), sans-serif;
	font-size: var(--p-font-size);
	font-weight: var(--p-font-weight);
}

body span {
	font-family: var(--p-font-family), sans-serif;
}

/* CheckWorks & AdminSite Styles */
body.CheckWorks, #AdminSite {
	/* Fonts */
	--h1-font-family: "Fira Sans";
	--h1-font-size: 32px;
	--h1-font-weight: bold;
	--h2-font-family: "Fira Sans";
	--h2-font-size: 30px;
	--h2-font-weight: normal;
	--h3-font-family: "Noto Sans";
	--h3-font-size: 24px;
	--h3-font-weight: bold;
	--h4-font-family: "Noto Sans";
	--h4-font-size: 20px;
	--h4-font-weight: normal;
	--h5-font-family: "Noto Sans";
	--h5-font-size: 18px;
	--h5-font-weight: normal;
	--h6-font-family: "Noto Sans";
	--h6-font-size: 16px;
	--h6-font-weight: normal;
	--p-font-family: "Noto Sans";
	--p-font-size: 16px;
	--p-font-weight: normal;

	/*General Colors*/
	--primary-color: #00A9CE;
	--primary-shadow: #1D252D;
	--primary-color-light: #00A9CE;
	--primary-extra-light: #00FBB6;
	--primary-medium: #1D428A;
	--warning-color: #F5AB00;
	--danger-color: #DC3545;
	--light-primary-color: #c2cfda;
	--dark-primary-color: #141B4D;
	--darker-primary-color: #1D252D;
	--info-light: #FFE29E;
	--info-dark: #ffd370;
	--gray: #667085;
	--dark-gray: #475467;
	--medium-gray: #BABABA;
	--light-gray: #D0D5DD;
	--background-primary-color: #F8F9FA;
	--gray-ribbon-color: #F9FAFB;
	--on-light: #333;
	--primary-fill: linear-gradient(to right, var(--primary-medium) 60%, var(--primary-color-light), var(--primary-extra-light));

	/* Button Styles */
	--btn-primary-background: var(--primary-color);
	--btn-primary-border: 4px solid var(--primary-color);
	--btn-primary-color: #fff;
	--btn-primary-hover-background: #fff;
	--btn-primary-hover-color: var(--primary-color);
	--btn-primary-hover-border: 4px solid var(--primary-color);
	--btn-secondary-background: var(--dark-primary-color);
	--btn-secondary-color: #fff;
	--btn-secondary-border: 4px solid var(--dark-primary-color);
	--btn-secondary-hover-background: #fff;
	--btn-secondary-hover-color: var(--dark-primary-color);
	--btn-secondary-hover-border: 4px solid var(--dark-primary-color);
	--btn-primary-border-radius: 10px;
	--btn-secondary-border-radius: 10px;

	--btn-danger-background: var(--danger-color);
	--btn-danger-border: 4px solid var(--danger-color);
	--btn-danger-color: #fff;
	--btn-danger-hover: #8a1d28;

	--input-background: #fff;
	--input-border: 1px solid var(--dark-primary-color);
	--input-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

	--thirty-percent-more-badge-outline: #073863;
	--thirty-percent-more-badge-contents: #ffffff;

	/* Home Page Top-to-Bottom */
	--user-action-bar-background:#E4E5EF;
	--user-action-bar-color: var(--primary-medium);
	--user-action-bar-icon-filter: none;
	--user-action-hover: var(--primary-medium);

	--banner-background: #FBB7C3;
	--banner-color: #1D2939;
	--banner-icon-background: #F06B84;

	--navbar-background: var(--dark-primary-color);
	--navbar-color: white;
	--nav-children-container-background: #F2F4F7;
	--nav-children-title-color: #0D67B5;

	--cart-btn-background: var(--btn-primary-background);
	--cart-btn-border: var(--btn-primary-border);
	--cart-btn-color: var(--btn-primary-color);
	--cart-btn-shadow: var(--primary-shadow);

	--hero-text-background: radial-gradient(var(--primary-medium) 40%, var(--dark-primary-color));
	--banner-button-background: var(--primary-color);

	--card-item-header-color: var(--darker-primary-color);
	--card-item-hr-color: #EAECF0;
	--see-more-btn-background: var(--btn-primary-background);
	--see-more-btn-border: var(--btn-primary-border);
	--see-more-btn-color: var(--btn-primary-color);
	--see-more-btn-shadow: var(--primary-shadow);

	--why-sitename-background-home: #F9FAFB;
	--why-sitename-background-product: #F1F8FE;
	--why-sitename-subheading-color: var(--primary-color);
	--why-sitename-heading-color: var(--darker-primary-color);
	--why-sitename-body-color: var(--gray);
	--why-sitename-body-color2: #475467;
	--why-sitename-btn-background: var(--btn-primary-background);
	--why-sitename-btn-border: var(--btn-primary-border);
	--why-sitename-btn-color: var(--btn-primary-color);
	--why-sitename-btn-shadow: var(--primary-shadow);

	--security-background: #F9FAFB;
	--security-title-color: var(--darker-primary-color);
	--security-item-color: #475467;
	--security-item-icon-filter: none;

	--subscribe-background: var(--primary-medium);
	--subscribe-heading-color: #fff;
	--subscribe-body-color: #fff;
	--subscribe-btn-background: var(--btn-secondary-background);
	--subscribe-btn-border: var(--btn-secondary-border);
	--subscribe-btn-color: var(--btn-secondary-color);

	--footer-contact-background: var(--dark-primary-color);
	--footer-links-heading-color: #fff;
	--footer-links-color: #fff;
	--footer-links-hover: #2DCCD3;

	--contact-footer-logo-color: #fff;
	--contact-footer-text-color: var(--primary-color-light);
	--contact-footer-btn-background: #fff;
	--contact-footer-btn-border: 4px solid var(--primary-color);
	--contact-footer-btn-border-hover: 4px solid #fff;
	--contact-footer-btn-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	--contact-footer-btn-color: var(--primary-color);
	--contact-footer-icon-filter: none;

	--socials-border-top: 1px solid var(--primary-extra-light);
	--socials-color: var(--primary-color-light);
	--socials-icon-filter: none;

	/* Misc Styles */
	--breadcrumb-inactive-color: var(--dark-primary-color);
	--breadcrumb-active-color: var(--primary-color);
	--breadcrumb-separator-color: #D0D5DD;
	--breadcrumb-parent-color: var(--primary-color);

	--cart-overview-discount: #344054;

	--category-header-background: #F1F8FE;
	--category-header-color: var(--dark-primary-color);
	--category-child-color: var(--darker-primary-color);
	--category-view-name: var(--dark-primary-color);
	--category-view-child-name: var(--darker-primary-color);

	--tracking-need-help-icon-border: 2px solid #2C88D9;
	--tracking-need-help-icon-background: #E2ECFB;
	--tracking-need-help-color: #2C88D9;

	--promotion-background: #E2F1FD;
	--promotion-color: var(--primary-color);

	/* --product-details-continue-btn-background: var(--primary-color);
	--product-details-continue-btn-color: white; */

	--checkout-sign-in-background: var(--primary-fill);
	--checkout-sign-in-title-color: white;
	--checkout-sign-in-color: var(--primary-color-light);
	--checkout-sign-in-create-btn-background: var(--primary-color);
	--checkout-sign-in-create-btn-border: 1px solid var(--primary-color);
	--checkout-sign-in-create-btn-color: white;

	--sign-in-title-color: #101828;

	--confirmation-modal-btn-background: var(--btn-primary-background);
	--confirmation-modal-btn-border: var(--btn-primary-border);
	--confirmation-modal-btn-color: var(--btn-primary-color);
	--confirmation-modal-btn-shadow: var(--primary-shadow);

	--scroll-top-btn-hover: var(--darker-primary-color);
}

/* ChecksForFree Styles */
body.ChecksForFree {
	/* Fonts */
	--h1-font-family: "Quicksand";
	--h1-font-size: 36px;
	--h1-font-weight: bold;
	--h2-font-family: "Quicksand";
	--h2-font-size: 30px;
	--h2-font-weight: normal;
	--h3-font-family: "Oxygen";
	--h3-font-size: 24px;
	--h3-font-weight: bold;
	--h4-font-family: "Oxygen";
	--h4-font-size: 20px;
	--h4-font-weight: normal;
	--h5-font-family: "Oxygen";
	--h5-font-size: 18px;
	--h5-font-weight: normal;
	--h6-font-family: "Oxygen";
	--h6-font-size: 16px;
	--h6-font-weight: normal;
	--p-font-family: "Oxygen";
	--p-font-size: 16px;
	--p-font-weight: normal;

	/*General Colors*/
	--primary-color: #FF6A39;
	--primary-color-light: #FF9C39;
	--primary-shadow: #DC582A;
	--primary-medium: #253746;
	--primary-extra-light: #F8E08E;
	--dark-primary-color: #253746;
	--darker-primary-color: #0b1015;
	--danger-color: #DC3545;
	--info-light: #FFE29E;
	--info-dark: #ffd370;
	--gray: #667085;
	--dark-gray: #475467;
	--medium-gray: #BABABA;
	--light-gray: #D0D5DD;
	--background-primary-color: #F8F9FA;
	--gray-ribbon-color: #F9FAFB;
	--on-light: #333;
	--primary-fill: linear-gradient(to right, var(--primary-color) 60%, var(--primary-color-light), var(--primary-extra-light));

	/* Button Styles */
	--btn-primary-background: var(--primary-color);
	--btn-primary-border: 4px solid var(--primary-color);
	--btn-primary-color: #fff;
	--btn-primary-hover-background: #fff;
	--btn-primary-hover-border: 4px solid var(--primary-color);
	--btn-primary-hover-color: var(--primary-color);
	--btn-primary-border-radius: 10px;
	--btn-secondary-background: var(--dark-primary-color);
	--btn-secondary-border: 4px solid var(--dark-primary-color);
	--btn-secondary-color: #fff;
	--btn-secondary-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	--btn-secondary-hover-background: #fff;
	--btn-secondary-hover-border: 4px solid var(--dark-primary-color);
	--btn-secondary-hover-color: var(--dark-primary-color);
	--btn-secondary-border-radius: 10px;

	--btn-danger-background: var(--danger-color);
	--btn-danger-border: 4px solid var(--danger-color);
	--btn-danger-color: #fff;
	--btn-danger-hover: #8a1d28;
	
	--input-background: #fff;
	--input-border: 1px solid var(--dark-primary-color);
	--input-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

	--thirty-percent-more-badge-outline: #073863;
	--thirty-percent-more-badge-contents: #ffffff;
	
	/*Home Page Top-to-Bottom*/
	--user-action-bar-background: #E4E5EF;
	--user-action-bar-color: var(--dark-primary-color);
	--user-action-bar-icon-filter: invert(1); /*The icons were designed for CheckWorks. This is a way to change svg color via css*/
	--user-action-hover: var(--primary-color);

	--banner-background: #ADDBD8;
	--banner-color: #1D2939;
	--banner-icon-background: #3bcac1;

	--navbar-background: var(--dark-primary-color);
	--navbar-color: white;
	--nav-children-container-background: #F2F4F7;
	--nav-children-title-color: var(--primary-color);

	/* --cart-btn-background: var(--btn-primary-background);
	--cart-btn-border: var(--btn-primary-border);
	--cart-btn-color: var(--btn-primary-color);
	--cart-btn-shadow: var(--btn-primary-shadow); */

	--hero-text-background: linear-gradient(var(--primary-shadow), var(--primary-color), var(--primary-color-light));
	--banner-button-background: var(--dark-primary-color);

	--card-item-header-color: #0E2745;
	--card-item-hr-color: #0E2745;
	--see-more-btn-background: var(--btn-secondary-background);
	--see-more-btn-border: var(--btn-secondary-border);
	--see-more-btn-color: var(--btn-secondary-color);

	--why-sitename-background-home: #EFF1F5;
	--why-sitename-background-product: #EFF1F5;
	--why-sitename-subheading-color: var(--dark-primary-color);
	--why-sitename-heading-color: var(--dark-primary-color);
	--why-sitename-body-color: #475467;
	--why-sitename-body-color2: #475467;
	--why-sitename-btn-background: var(--dark-primary-color);
	--why-sitename-btn-border: 1px solid var(--dark-primary-color);
	--why-sitename-btn-color: #fff;
	--why-sitename-btn-shadow: var(--primary-shadow);

	--security-background: #F9FAFB;
	--security-title-color: var(--darker-primary-color);
	--security-item-color: #475467;
	--security-item-icon-filter: hue-rotate(165deg) brightness(105%); /*hue-rotate to change icon color without swapping black/white*/

	--subscribe-background: var(--primary-color);
	--subscribe-heading-color: #fff;
	--subscribe-body-color: #fff;
	--subscribe-btn-background: var(--dark-primary-color);
	--subscribe-btn-border: 4px solid var(--dark-primary-color);
	--subscribe-btn-shadow: var(--primary-shadow);
	--subscribe-btn-color: #fff;

	--footer-contact-background: var(--dark-primary-color);
	--footer-links-heading-color: #F9FAFB;
	--footer-links-color: #fff;
	--footer-links-color-shadow: #D15932;
	--footer-links-hover: var(--primary-color);

	--contact-footer-logo-color: #fff;
	--contact-footer-text-color: #fff;
	--contact-footer-btn-background: #FF6A39;
	--contact-footer-btn-border: 4px solid #fff;
	--contact-footer-btn-border-hover: 4px solid var(--primary-color);
	--contact-footer-btn-shadow: var(--footer-links-color-shadow);
	--contact-footer-btn-color: #fff;
	--contact-footer-icon-filter: brightness(500%) saturate(0%); /*crank up brightness & desaturate for white*/

	--socials-border-top: transparent;
	--socials-color: #F9FAFB;
	--socials-icon-filter: brightness(500%) saturate(0%); /*crank up brightness & desaturate for white*/

	/* Misc Styles */
	--breadcrumb-inactive-color: var(--dark-primary-color);
	--breadcrumb-active-color: var(--primary-color);
	--breadcrumb-separator-color: #D0D5DD;
	--breadcrumb-parent-color: var(--primary-color);

	--cart-overview-discount: #344054;

	--category-header-color: var(--dark-primary-color);
	--category-child-color: var(--darker-primary-color);
	--category-view-name: var(--dark-primary-color);
	--category-view-child-name: var(--darker-primary-color);

	--tracking-need-help-icon-border: 2px solid var(--primary-color);
	--tracking-need-help-icon-background: #ffeae2;
	--tracking-need-help-color: var(--primary-color);

	--promotion-background: var(--background-primary-color);
	--promotion-color: var(--primary-color);

	/* --product-details-continue-btn-background: var(--primary-color);
	--product-details-continue-btn-color: white; */

	--checkout-sign-in-background: var(--primary-fill);
	--checkout-sign-in-title-color: white;
	--checkout-sign-in-color: white;
	--checkout-sign-in-create-btn-background: var(--primary-color);
	--checkout-sign-in-create-btn-border: 1px solid var(--primary-color);
	--checkout-sign-in-create-btn-color: white;

	--sign-in-title-color: #101828;

	--confirmation-modal-btn-background: var(--btn-primary-background);
	--confirmation-modal-btn-border: var(--btn-primary-border);
	--confirmation-modal-btn-color: var(--btn-primary-color);
	--confirmation-modal-btn-shadow: var(--primary-shadow);

	--scroll-top-btn-hover: var(--darker-primary-color);
}

code {
	color: #E01A76;
}

a, .btn-link {
	color: var(--primary-color);
	font-weight: bold;
}

a:hover, .btn-link:hover {
	color: var(--primary-shadow);
}

.btn-primary, .btn-primary.active, .btn-primary:active {
	box-sizing: border-box;

	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 8px;

	background-color: var(--btn-primary-background);
	color: var(--btn-primary-color);
	border-radius: var(--btn-primary-border-radius);
	border: var(--btn-primary-border);
	padding: 10px 15px;
	transition: 0.5s;

	flex: none;
	flex-grow: 0;
}

.btn-success {
	box-sizing: border-box;

	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px 18px;
	gap: 8px;

	border: 4px solid var(--bs-btn-bg);
	border-radius: var(--btn-secondary-border-radius);
	
	flex: none;
	flex-grow: 0;
}

.btn-danger {
	box-sizing: border-box;

	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px 18px;
	gap: 8px;

	color: var(--btn-danger-color);
	background: var(--btn-danger-background);
	border-radius: var(--btn-secondary-border-radius);
	border: 4px solid var(--bs-btn-bg);
	box-shadow: var(--primary-shadow);

	flex: none;
	flex-grow: 0;
}

.btn-primary:hover {
	background-color: var(--btn-primary-hover-background) !important;
	color: var(--btn-primary-hover-color);
	border: var(--btn-primary-hover-border);
}

.btn-primary:disabled, .btn-outline-primary:disabled, .btn-secondary:disabled {
	border: none;
}

.btn-outline-primary {
	border-color: var(--primary-color);
	color: var(--primary-color);
}

.btn-secondary, .btn-secondary.active, .btn-secondary:active {
	box-sizing: border-box;

	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	/*padding: 10px 28px;*/
	gap: 8px;
	
	background-color: var(--btn-secondary-background);
	color: var(--btn-secondary-color);
	border-radius: var(--btn-secondary-border-radius);
	border: var(--btn-secondary-border);
	padding: 10px 15px;
	transition: 0.5s;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;
}

.btn-secondary:hover {
	background-color: var(--btn-secondary-hover-background);
	color: var(--btn-secondary-hover-color);
	border: var(--btn-secondary-hover-border);
}

.btn-success:hover {
	color: var(--bs-btn-bg);
	background-color: #fff;
	border: 4px solid var(--bs-btn-bg);
}

.btn-danger:hover {
	color: var(--bs-btn-bg);
	background-color: #fff;
	border: 4px solid var(--bs-btn-bg);
}

.form-check-input:checked[type=radio] {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
}

.form-check-input:checked[type=checkbox] {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
}

.flex-spacer {
	flex-grow: 5;
	padding: 0.1rem;
}

.hidden-anchor {
	height: 0;
	visibility: hidden;
}

.bolded-text {
	font-weight: bold;
}

.modal-back-btn {
	color: #000;
	opacity: 0.5;
	cursor: pointer;
	height: 21px;
}
.modal-back-btn:hover {
	opacity: 0.75;
}

@media (max-width: 768px) {
	.hide-on-mobile {
		display: none;
	}
}

@media (min-width: 769px) {
	.hide-on-desktop {
		display: none;
	}
}

.med-max-width {
	max-width: 150px;
}

.card-header {
	align-items: center;
	background-color: white;
	display: flex;
	min-height: 65px;
}

.centered-spinner {
	display: flex;
	justify-content: center;
	margin-top: 5rem;
}

.nav-item {
	cursor: pointer;
}

.floating-toast {
	display: flex;
	justify-content: center;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 100px;
	width: 100%;
	z-index: 99999;
	pointer-events: none;
}

.criteria-panel-control-container div {
	max-height: none !important;
}

.text-right {
	text-align: right;
}

.confirmation-modal-list-points-container {
	margin-top: 1rem;
	white-space: pre;
}
