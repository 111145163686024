
.address-input-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    position: relative;
    width: 100%;
}

.address-input-container-edit-mode {
    justify-content: space-between;
}

.address-input-container span {
    margin-bottom: 0.5rem;
}

.address-input-label {
    font-weight: bold;
}

.non-input-label {
    font-size: var(--h5-font-size);
    font-weight: var(--h5-font-weight);
    color: var(--darker-primary-color); 
}

.address-input-configuration-suggested-addresses {
    position: absolute;
    background: #fff;
    border: 1px solid var(--primary-color);
    border-radius: 0.375rem;
    z-index: 9999;
}

.address-input-configuration-suggested-address-btn {
    display: block;
    width: 100%;
    text-align: start;
}

.address-input-configuration-suggested-address-btn:hover, .address-input-configuration-suggested-address-btn:focus  {
    background-color: var(--medium-gray);
    color: black !important;
}

@media (max-width: 400px) {
    .address-input-container .address-input-suggested-address-container {
        flex-direction: column-reverse;
    }    
}