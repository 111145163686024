.subscribe-section {
    gap: 32px;
    background: linear-gradient(to right, var(--subscribe-background) 60%, var(--primary-color-light), var(--primary-extra-light)); 
    align-items: center;
    padding: 20px 0px;
    align-self: stretch;
    justify-content: space-around;
}

.subscribe-content {
    gap: 82px;
    flex-direction: row;
    justify-content: space-between;
}

.subscribe-text-container {
    gap: 8px;
}

.subscribe-title-text {
    line-height: 38px;
    color: var(--subscribe-heading-color);
    align-self: stretch;
}

.subscribe-body-text {
    color: var(--subscribe-body-color);
    align-self: stretch;
    margin-bottom: 0; 
}

.subscribe-email-capture {
    padding: 0px;
    gap: 12px;
    margin: 0px 0 25px auto;
    flex-direction: row;
}

.subscribe-email-input {
    box-sizing: border-box;
    align-items: center;
    gap: 6px;
    padding: 7px 14px;
    background: var(--input-background);
    border: var(--input-border);
    box-shadow: var(--input-shadow);
    border-radius: 4px;
    align-self: stretch;
    width: 70%;
}

.subscribe-error-field {
    border: 2px solid red;
}


.subscribe-toast {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 100px;
    width: 100%;
}

.subscribe-toast div.simple-toast {
    margin: auto;
}

.subscribe-email-button {
    background: var(--subscribe-btn-background) !important;
    color: var(--subscribe-btn-color) !important;
    border: 4px solid #fff !important; 
    border-radius: var(--primary-btn-border-radius);
    justify-content: center;
    align-items: center;
    padding: 7px 10px;
    gap: 8px;
    transition: 0.8s; 
}
.subscribe-email-button:hover {
    background-color: #fff !important; 
    border: var(--subscribe-btn-border) !important;
    color: var(--subscribe-btn-background) !important; 
}

/* Tablet screen size */
@media only screen and (min-width: 769px) and (max-width: 1200px)
{
    .subscribe-content {
        flex-direction: column;
        gap: 30px;
    }

    .subscribe-email-capture {
        margin: 0px;
    }
}

/* Mobile screen size */
@media only screen and (max-width: 768px) {
    .subscribe-content {
        flex-direction: column;
        gap: 30px;
    }

    .subscribe-email-capture {
        padding: 0px;
        gap: 12px;
        margin: 0px;
        flex-direction: column;
    }

    .subscribe-email-input {
        width: 75%;
    }
    
    .subscribe-email-button {
        width: 30%; 
    }
}