
.why-checkworks-section-home {
	justify-content: center;
	align-items: center;
	gap: 64px;
	background: var(--why-sitename-background-home);
}

.why-checkworks-section-product {
	align-items: center;
	gap: 64px;
	background: var(--why-sitename-background-product);
}

.why-checkworks-container {
	justify-content: center;
	align-items: center;
	padding-top: 32px;
	padding-bottom: 64px;
	gap: 32px;
}

.why-checkworks-content {
	align-items: flex-start;
	padding: 0;
	gap: 32px;
}

.why-checkworks-heading {
	align-items: flex-start;
	padding: 0;
	gap: 12px;
}

.sub-heading {
	color: var(--why-sitename-subheading-color);
}

.header-text {
	line-height: 44px;
	letter-spacing: -0.02em;
	color: var(--why-sitename-heading-color);
}

.why-checkworks-body-container {
	gap: 40px;
}

.why-checkworks-body {
	align-items: flex-start;
	padding: 0;
	gap: 32px;
}

.body-text {
	line-height: 24px;
	color: var(--why-sitename-body-color);
}

.why-checkworks-button {
	align-items: flex-start;
	padding: 0;
	gap: 12px;
}

.why-checkworks-text-body-content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 64px;
}

.body-text-v2 {
	line-height: 28px;
	color: var(--why-sitename-body-color2);
}

@media only screen and (max-width: 768px) {
	.why-checkworks-container {
		padding-top: 32px;
		padding-bottom: 64px;
	}

	.why-checkworks-content {
		gap: 32px;
	}

	.why-checkworks-body-content {
		flex-direction: column;
	}

	.why-checkworks-text-body-content {
		grid-template-columns: 1fr;
		gap: 16px;
	}
}
