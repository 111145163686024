.sign-in-page {
    background: var(--background-primary-color);
    justify-content: center;
    padding: 1rem;
}

.sign-in-page-container {
    background: #FFFFFF;
    box-shadow: 2px 2px #f0f2f5;
    flex: 1;
    gap: 32px;
    max-width: 800px;
    padding: 3rem;
}

.sign-in-content {
    flex-direction: row;
}
