


.container {
    display: flex;
    flex-flow: column;
    padding: 0 1rem;
    width: 100%;
}

.card {
    margin-top: 1rem;
}

.cardHeader {
    display: flex;
    font-weight: bold;
    font-size: 125%;
    justify-content: space-between;
}

.inputLabel {
    font-size: 120%;
    font-weight: bold;
}

.inputButtonContainer {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.input {
    padding: 10px 18px;
}

.inputButton {
    margin-left: 0.5rem;
}

.inputContainer {
    width: 100%;
}

.inputButtonsRow {
    display: flex;
    margin-bottom: 1rem;
}

.inputButtonContainer:last-child > .inputButtonsRow {
    margin-bottom: 0;
}

/*small screens*/
@media (max-width: 768px) {
    .container {
        padding: 0;
    }
    
    .card {
        width: 100%;
    }
    
    .inputButtonContainer {
        flex-wrap: wrap;
    }
    
    .inputButtonsRow {
        margin-top: 1rem;
        flex-direction: row-reverse;
    }
    
    .inputButton:last-child {
        margin-left: 0;
    }
}

/*large screens*/
@media (min-width: 768px) {
    .card {
        min-width: 500px;
        max-width: 80%;
    }
}
