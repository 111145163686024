
.cart-product-view-container {
    max-width: 800px;
}

@media (max-width: 768px) {
    .cart-product-view-card {
        flex-direction: column;
    }
    .cart-product-view-image {
        display: flex;
        justify-content: center;
    }

    .cart-product-view-image img {
        width:95%;
    }
}

@media (min-width: 768px) {
    .cart-product-view-card {
        flex-direction: row;
        margin-right: 20px;
    }

    .cart-product-view-image img {
        width: 200px;
    }
}

.cart-product-view-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0 2px 4px -2px rgba(16, 24, 40, 0.06);
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    padding: 5%;
}

.cart-product-view-information {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    width: 100%;
}

@media (max-width: 768px) {
    .cart-product-view-information-row {
        flex-direction: column;
    }
    .cart-product-view-information {
        margin: 1rem 1rem 0 1rem;
    }
}
.cart-product-view-information-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.cart-product-view-information-row-left-align {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.cart-product-view-delete-button {
    color: var(--btn-danger-background);
}

.cart-product-view-delete-button:hover {
    color: var(--btn-danger-hover); 
}

.descriptive-configuration-value {
    font-size: 16px;
    margin: 0.5rem 0.2rem;
}