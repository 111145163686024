
.micr-text-input-container {
    display: flex;
    margin: 1rem 0;
    position: relative;
}

.micr-text-input-container .invalid-tooltip {
    bottom: 110%;
    position: absolute;
    right: 0;
    top: auto;
}

.micr-text-input-column {
    display: flex;
    flex-direction: column;
}

.micr-text-input-value {
    border-radius: 0;
    padding: 0;
    text-align: center;
}

.micr-text-input-value.is-invalid {
    background-image: none;
    padding: 0;
}


input.micr-text-input-value:focus {
    z-index: 0;
}

.micr-text-input-index-text {
    text-align: center;
}

.micr-text-input-index-text-focused {
    font-weight: bold;
}