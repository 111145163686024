

.add-on-details-container {
    border: 1px solid var(--light-gray);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-flow: column;
    margin: 1rem 0;
    padding: 2rem;
    width: 47%;
}

.add-on-details-container ul {
    padding-left: 1.1rem;
}


@media (max-width: 768px) {
    .add-on-details-container {
        width: 97%;
    }
}

.add-on-header {
    display: flex;
    justify-content: space-between;
}

.add-on-name {
    color: var(--dark-primary-color);
    font-weight: bold;
}

.add-on-price {
    background: var(--promotion-background);
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    border-radius: 16px;
    padding: 2px 10px;
}

.add-on-details-selected {
    border: 1px solid var(--primary-color);
    background: var(--category-header-background);
}