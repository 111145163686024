
.order-product-summary-line-item-container {
    display: flex;
    flex-wrap: wrap;
}

.order-product-summary-line-item {
    display: flex;
    width: 50%;
}

.order-product-summary-line-item span {
    font-size: 110%;
    padding-right: 1rem;
    min-width: 120px;
}

.order-product-summary-line-item-label {
    font-weight: bold;
}

.order-product-summary-product-select-input {
    width: 50%;
}
.product-summary-pricing-details-container{
    display: flex;
    gap: 30px;
}

.product-summary-grid{
    overflow-x: scroll;
    margin: 1rem 0;
}