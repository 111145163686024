
.container {
    --gap: 1rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(10rem, 1fr));
    gap: var(--gap);
    margin-bottom: var(--gap);
}
@media(max-width: 500px){
    .container{
        /*Don't let inputs overflow when really small*/
        grid-template-columns: 1fr;
    }
}

.addressInput {
    width: 100%;
}

.suggestedAddress {
    display: block;
    width: 100%;
    text-align: start;
}

.suggestedAddress:hover, .suggestedAddress:focus {
    background-color: var(--primary-extra-light);
    color: var(--primary-color);   
}

.suggestedAddressesOld {
	background: #fff;
	border: 1px solid var(--primary-color);
	border-radius: 0.375rem;
	z-index: 9999;
	width: auto;
	position: absolute;
	margin-top: 2px;
	margin-left: 6px;
}

.suggestedAddresses {
    background: #fff;
    border: 1px solid var(--primary-color);
    border-radius: 0.375rem;
    z-index: 9999;
    width: auto;
    position: absolute;
    top: 100%;
	margin-top: -14px;
	margin-left: 18px;
}