
.navigation-item-link-text {
    display: flex;
    font-size: var(--p-font-size); 
    font-weight: var(--p-font-weight);
    color: #F9FAFB;  
}

.navigation-item-link-text span{
    display: block;
    position: relative;
    overflow: hidden; 
}

.navigation-item-link-text span::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -1px;
    width: 98%;
    height: 0.1em;
    background-color: var(--primary-color); 
    opacity: 1;
    transform: translate3d(-100%, 0, 0); 
    transition: opacity 300ms, transform 300ms;
}

.navigation-item-link-text span:hover::after, .navigation-item-link-text span:focus::after {
    transform: translate3d(0, 0, 0); 
}

.navigation-item-link-text:hover {
    color: var(--primary-color); 
}

.navigation-item-link-text span {
    white-space: nowrap;
}

.navigation-item-link-text:hover {
    opacity: 0.8;
}

.navigation-item-link-container {
    align-items: center;
    display: flex;
    margin: 0 15px 0 0;
    padding: 0 5px;
}

.navigation-item-link-container:hover {
    cursor: pointer;
}

.padding-right {
    padding-right: .4rem;
}

.flipped-svg {
    transform: rotate(180deg);
}