

@media (max-width: 768px) {
    .user-profile-container {
        flex-direction: column;
        margin: 1rem;
    }
}

@media (min-width: 768px) {
    .user-profile-left-side-content {
        padding-left: 3rem;
    }

    .user-profile-container {
        margin: 4rem 1rem;
    }
}

.user-profile-container {
    display: flex;
}

.user-profile-left-side-content {
    align-items: flex-start;
    display: flex;
    flex-flow: column;
    width: 300px;
}

.user-profile-center-content {
    display: flex;
    width:100%;
}

.user-profile-navigation-element {
    cursor: pointer;
    padding: 0.5rem 1rem;
    width: 100%;
}

.selected-profile-tab {
    background-color: #F2F4F7;
}