.image-container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

span.package-track-span {
    margin-left: 10px;
}

.order-details-pricing-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.order-details-overview-container {
    margin-bottom: 1rem;
}

.order-details-nav-item a{
    color: var(--gray); 
}