.toggle-group {
    display: flex;
    flex-direction: column;
}

.row {
    margin-bottom: 10px;
}

.action-btn-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 130px;
}

.action-btn-container, .action-btn-group {
    display: flex;
}

.action-btn-group button:first-of-type {
    margin-left: 0px;
}

.action-btn-container button {
    max-height: 40px;
    margin-bottom: 7px;
}

.optional-text {
    color: var(--medium-gray);
    font-style: italic;
    font-weight: 400;
    margin-left: 4px;
}

@media (min-width: 576px) {
    .delete-btn {
        max-width: 250px;
    }
}