.signature-radio-option {
    border: 1px solid #999999;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.signature-radio-option:hover {
    border: 1px solid #0A4E8A;
}

.signature-radio-option label {
    margin: 12px;
    display: flex;
    align-items: center;
    width: 100%;
}

.signature-radio-option span {
    margin-left: 12px;
    width: 100%;
    min-width: 150px;
}

/* Applied via code when checked. */
.signature-radio-option-checked {
    border: 2px solid #0A4E8A !important;
    background-color: #b6effb;
}

.signature-message-preview {
    min-height: 3rem;
}

