.copyright-and-socials-container {
    gap: 32px;
    width: 100%;
    align-items: stretch !important;
    flex-direction: column;
}

.copyright-and-socials-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    border-top: var(--socials-border-top);
}

.copyright-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--socials-color);
}

.socials-container {
    display: none !important;
    align-items: center;
    gap: 24px;
    margin-left: auto; 
    margin-right: 0;
}

.socials-icon {
    filter: var(--socials-icon-filter);
}

/* Mobile screen size */
@media only screen and (max-width: 768px) {
    .copyright-and-socials-content {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .socials-container {
        margin: 0;
    }
}