.price-grid {
    text-align: center;
}

.price-grid caption {
    caption-side: top;
    background-color: var(--dark-primary-color);
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
}