/* FontSelector */

.font-block {
	font-family: font-block, sans-serif;
}

.font-broadway {
	font-family: font-broadway, sans-serif;
}

.font-calligraphy {
	font-family: font-calligraphy, serif;
}

.font-classic-block {
	font-family: font-classic-block, serif;
}

.font-classic-condensed {
	font-family: font-classic-condensed, serif;
}

.font-excalibur {
	font-family: font-excalibur, serif;
}

.font-executive {
	font-family: font-executive, serif;
}

.font-flair {
	font-family: font-flair, serif;
}

.font-garamond {
	font-family: font-garamond, serif;
}

.font-handwriting {
	font-family: font-handwriting, serif;
}

.font-title-plate {
	font-family: font-title-plate, serif;
}


@font-face {
	font-family: 'font-calligraphy';
	src: url(../../assets/fonts/Calligraphy.otf) format('opentype');
}

@font-face {
	font-family: 'font-classic-condensed';
	src: url(../../assets/fonts/CL212.otf) format('opentype');
}

@font-face {
	font-family: 'font-title-plate';
	src: url(../../assets/fonts/CP212.otf) format('opentype');
}

@font-face {
	font-family: 'font-excalibur';
	src: url(../../assets/fonts/EX212.TTF) format('truetype');
}

@font-face {
	font-family: 'font-flair';
	src: url(../../assets/fonts/Flair.otf) format('opentype');
}

@font-face {
	font-family: 'font-garamond';
	src: url(../../assets/fonts/GARA.TTF) format('truetype');
}

@font-face {
	font-family: 'font-block';
	src: url(../../assets/fonts/Helvetica.ttf) format('truetype');
}

@font-face {
	font-family: 'font-handwriting';
	src: url(../../assets/fonts/HW212.TTF) format('truetype');
}

@font-face {
	font-family: 'font-executive';
	src: url(../../assets/fonts/KP212.otf) format('opentype');
}

@font-face {
	font-family: 'font-classic-block';
	src: url(../../assets/fonts/PR212.otf) format('opentype');
}

@font-face {
	font-family: 'font-broadway';
	src: url(../../assets/fonts/RV212.TTF) format('truetype');
}
