
.order-product-comparison-pricing {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
}

.order-product-comparison-pricing span {
    text-align: end;
}

.order-edit-product-comparison-grid {
    overflow-x: scroll;
    margin: 1rem 0;
}

.order-edit-product-comparison-pricing-details-container {
    display: flex;
    gap: 30px;
}

.low-margin-horizontal-bar {
    margin: 0.1rem 0;
}

.order-product-comparison-pricing-higher {
    background: #C1E1C1;
}

.order-product-comparison-pricing-lower {
    background: #FDAAAA;
}
