

.order-details-tab-badge {
    font-size: 90%;
    margin-right: 1rem;
}

.order-details-update-order-button {
    margin: 0.5rem 1rem;
    padding: 10px 18px;
}