
@keyframes border-pulsate {
    0%   { border-color: rgba(50, 36, 39, 1); }
    50%  { border-color: rgba(50, 36, 39, 0); }
    100% { border-color: rgba(50, 36, 39, 1); }
}

.required-fields-toast {
    border: 5px solid red;
    animation: border-pulsate 2s infinite;
}