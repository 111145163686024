
.product-input-view {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.product-input-view label {
    font-weight: var(--p-font-weight);
    font-size: var(--p-font-size);
    color: var(--cart-overview-discount);
}