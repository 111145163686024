
.card-item-browse-container {
  margin-bottom: 50px;
}

.card-item-previews {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px 30px;
    margin-bottom: 30px;
}

@media (max-width: 992px) {
  .card-item-previews {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
    .card-item-previews {
        grid-template-columns: 1fr;
    }
}

.card-item-header {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 20px 0 16px 0;
}

.card-item-title {
  font-size: 30px;
  color: var(--card-item-header-color);
}

.card-item-browse-hr {
  color: var(--card-item-hr-color);
  opacity: 1;
  margin: 0.3rem 0 1rem 0;
}