
.showPasswordButton {
    /*
        Reactstrap doesn't realize this button is the end of the input group and should have rounded right corners.
        The FormFeedback must be a sibling of the Input, so it has to go "into" the InputGroup,
        but it doesn't actually appear inside the InputGroup.
     */
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
    
    padding: 0 7px;
}