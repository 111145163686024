.reset-password-page {
    background: var(--background-primary-color);
    justify-content: center;
    padding: 1rem;
}

.reset-password-page-container {
    background: #FFFFFF;
    box-shadow: 2px 2px #f0f2f5;
    flex: 1;
    gap: 32px;
    max-width: 800px;
    padding: 3rem;
}

.reset-password-content {
    flex-direction: row;
}


.reset-password-form-title {
    font-size: 32px;
    line-height: 40px;
    color: var(--sign-in-title-color);
    margin-bottom: 2rem;
}

.reset-password-password-input-group {
    position: relative;
}

.reset-password-password-show-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.sign-in-btn {
    max-width: 100px;
}

#password-input {
    background-image: none;
}
