@font-face {
    font-family: 'font-calligraphy';
    src: url(../../../assets/fonts/Calligraphy.otf) format('opentype');
}
@font-face {
    font-family: 'font-classic-condensed';
    src: url(../../../assets/fonts/CL212.otf) format('opentype');
}
@font-face {
    font-family: 'font-title-plate';
    src: url(../../../assets/fonts/CP212.otf) format('opentype');
}
@font-face {
    font-family: 'font-excalibur';
    src: url(../../../assets/fonts/EX212.TTF) format('truetype');
}
@font-face {
    font-family: 'font-flair';
    src: url(../../../assets/fonts/Flair.otf) format('opentype');
}
@font-face {
    font-family: 'font-garamond';
    src: url(../../../assets/fonts/GARA.TTF) format('truetype');
}
@font-face {
    font-family: 'font-block';
    src: url(../../../assets/fonts/Helvetica.ttf) format('truetype');
}
@font-face {
    font-family: 'font-handwriting';
    src: url(../../../assets/fonts/HW212.TTF) format('truetype');
}
@font-face {
    font-family: 'font-executive';
    src: url(../../../assets/fonts/KP212.otf) format('opentype');
}
@font-face {
    font-family: 'font-classic-block';
    src: url(../../../assets/fonts/PR212.otf) format('opentype');
}
@font-face {
    font-family: 'font-broadway';
    src: url(../../../assets/fonts/RV212.TTF) format('truetype');
}



@media (max-width: 768px) {
    .radio-select-options-container {
        display: flex;
        flex-flow: column;
    }
}

.button-single-select-container {
    width: 100%;
    margin: 1rem 0;
}

.button-single-select-option-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}


@media (max-width: 768px) {

    .button-single-select-option-container {
        flex-wrap: nowrap;
        overflow-x: scroll;
    }
}

.button-single-select-input-label {
    font-size: var(--h5-font-size) !important; 
    font-weight: var(--h5-font-weight) !important; 
}

.button-single-select-option {
    background: white;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    color: black;
    margin: 0.5rem 1rem 0.5rem 0;
}

.button-single-select-option.active {
    background-color: #fff !important; 
    color: var(--dark-primary-color) !important; 
}

.button-single-select-is-selected {
    background-color: darkgrey;
}

.font-block {
    font-family: font-block, serif;
}
.font-classic-condensed {
    font-family: font-classic-condensed, serif;
}
.font-title-plate {
    font-family: font-title-plate, serif;
}
.font-calligraphy {
    font-family: font-calligraphy, serif;
}
.font-flair {
    font-family: font-flair, serif;
}
.font-garamond {
    font-family: font-garamond, serif;
}
.font-executive {
    font-family: font-executive, serif;
}
.font-classic-block {
    font-family: font-classic-block, serif;
}
.font-broadway {
    font-family: font-broadway, serif;
}
.font-excalibur {
    font-family: font-excalibur, serif;
}
.font-handwriting {
    font-family: font-handwriting, serif;
}

.button-single-select-option-hide {
    display: none;
}

.text-normal {
    font-family: 'Inter', sans-serif;
    font-size: .8rem;
    font-style: unset;
    color: unset;
}