

@media (max-width: 768px) {
    .shipping-information-card-header {
        display: flex;
        flex-direction: column;
    }

    .shipping-information-title {
        margin-bottom: 1rem;
    }
}


@media (min-width: 768px) {
    .shipping-information-card-header {
        align-items: center;
    }
}

.shipping-information-card-header {
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

@media (max-width: 768px) {
    .shipping-information-row {
        flex-direction: column;
    }
}

.error-text {
    color: var(--danger-color);
}
