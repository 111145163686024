
.order-summary-card-body {
    border: 1px solid #ced4da;
    border-radius: 10px;
}

.order-summary-pricing-details-container {
    align-items: center;
    border-bottom: 1px solid #ced4da;
    display: flex;
    height: 50px;
    padding: 2rem 1rem;
}


.order-summary-pricing-details-label {
    margin-right: 1rem;
    width: 150px;
}

.order-summary-pricing-details-value, .order-summary-pricing-details-spinner {
    display: flex;
    flex-grow: 1;
}

.order-summary-pricing-details-shipping-container {
    align-items: center;
    border-bottom: 1px solid #ced4da;
    height: auto;
    padding: 1rem;
}

.order-summary-pricing-details-discount-table-container {

    border-bottom: 1px solid #ced4da;
    padding: 1rem;
}

.shipper-information {
    color: var(--gray);
    padding-left: 8px;
}