#StorefrontNav a.navbar-brand {
  margin-right: 0;
  text-align: center;
  white-space: normal;
}

html {
  font-size: 14px;
}
@media (min-width: 769px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar-container {
  background: var(--navbar-background);
  /*max-height: 112px;*/
}

#StorefrontNav .navbar-brand{
  color: var(--navbar-color) !important;
}

.product-catalog-navbar {
  display: flex;
  flex-wrap: wrap;
}

/* Desktop screen size */
@media only screen and (min-width: 769px) {
  .navbar-container {
    padding: 0 2%;
  }

  .product-catalog-navbar {
    padding: 1rem 0;
  }
}

.title-logo img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60px;
}

.title-logo.mobile img {
  height: 32px;
}


@media (max-width: 768px) {
  .navbar-title-site-name {
    display: none;
  }

  .navigation-menu-item-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.navigation-menu-item-container {
  display:flex; 
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 95%;
}

.chevron {
  align-self: center; 
}

.chevron-placeholder {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.navigation-menu-cart-button-container {
  display: flex;
  justify-content: flex-end;
}
  .navigation-menu-collapse-container.mobile {
    background: var(--navbar-color);
  }
    .navigation-menu-collapse-container.mobile ul.navigation-menu-item-container * {
      color: var(--darker-primary-color) !important;
    }

@media (max-width: 1421px) {

  .navigation-menu-cart-button-container {
    margin-right: 0.5rem;
  }

  .navigation-menu-collapse-container {
    width: 100%;
  }
}

@media (min-width: 1421px) {

  .navigation-menu-cart-button-container {
    order: 1;
  }

}

@media (max-width: 768px) {
  .product-catalog-navbar div {
    display: flex; 
    align-items: center; 
    justify-content: space-between;
  }
}

.navbar-toggler {
  border: none;
}

.navbar-toggler.closed .navbar-toggler-icon  {
  background-image: url('../../../assets/icons/navbar-toggler.svg');
}

.navbar-toggler.open .navbar-toggler-icon  {
  background-image: url('../../../assets/icons/close.svg');
}

.navbar-toggler-icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.mobile-cart-btn > svg {
  height: 22px;
  width: auto;
}

.cart-count {
  font-size: 16px;
  color: white;
  text-align: center;
  margin-left: 5px;
}