
.credit-card-details-entry-container {
    display: flex;
    flex-wrap: wrap;
}

.credit-card-details-entry-container-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.payment-details-entry-card-expiration-container {
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    display: flex;
    font-size: 24px;
    justify-content: space-around;
    margin-right: 1rem;
    position: relative;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.payment-details-entry-card-expiration-container:after {
    content: '/';
    position: absolute;
    left: 50%;
    margin-left: -4px;
    color: #aaa;
}

.payment-details-entry-card-expiration-container:focus-within {
    box-shadow: 0 0 0 4px rgba(0, 126, 255, 0.25);
    outline-width: thick;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input.payment-details-entry-card-expiration-input {
    appearance: none;
    border: 0;
    border-radius: 0;
    box-shadow: none !important;
    float: left;
    outline: none;
    margin-right: 0 !important;
    width: 100px !important;
}

.payment-details-entry-invisible-input {
    display: none;
}