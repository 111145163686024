.product-accessory-input-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    width: 100%;
    justify-content: flex-start;
    gap: 1rem;
}

.product-accessory-input-container  span {
    margin-bottom: 0.5rem;
}

.product-accessory-label {
    font-weight: bold;
}

.product-accessory-question-text {
    font-weight: bold;
    font-size: 18px;
    margin-top: 0.5rem;
    width:100%;
}

.product-accessory-text-input {
    width: 50%;
    row-gap: 15px;
}

.product-accessory-input-select-container select {
    width: 65%;
}

.product-accessory-add-to-cart-button {
    margin-left:15px;
}