
.cart-button-icon-container {
    height: 15px;
}

.nav-cart-button {
    background: var(--cart-btn-background) !important;
    border: var(--cart-btn-border) !important;
    color: var(--cart-btn-color) !important;
    box-shadow: var(--cart-btn-shadow) !important;
}