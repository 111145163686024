
.logoPreview {
    max-width: 100%;
    height: 200px;
}

.logoPreviewContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}