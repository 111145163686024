
#OrdersGrid > :not(caption) > * > * {
    padding: 0.5rem 0.2rem;
}
#OrdersGrid{
    margin-bottom: 0;
}

.orders-grid-table-container {
    max-height: 100%;
    overflow: scroll;
    width: 100%;
}

.orders-grid-table-container table th, .grid-table-container table td{
    border: 0.5px solid;
    border-color: inherit;
}
.orders-grid-table-container .table td{
    border-width: 0.5px;
}
.orders-grid-table-container table th{
    background-color: white;
}
.orders-grid-table-container table thead{
    position: sticky;
    top: 0;
}
.orders-grid-table-container table {
    border-collapse: separate;
    border-spacing: 0;
}