/* Default Configuration Entry */

/* Chrome: Hide number spinner buttons. */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox: Hide number spinner buttons. */
input[type=number] {
	-moz-appearance: textfield;
}
