.simple-toast:hover {
    cursor: pointer;
}

.success-toast {
    background-color: green;
    border: 1px solid green;
    color: white;
    text-align: center; 
}

.error-toast {
    background-color: #d52744;
    border: 1px solid #d52744;
    color: white;
    text-align: center;
}