.links-container {
    width: 280px;
    justify-content: space-between;
    gap: 20px;
}

@media only screen and (max-width: 768px) {
    .links-container {
        width: 100%; 
        gap: 0px; 
    }
}

.header-links-container {
    min-width: 80px;
    gap: 16px;
}

.heading {
    line-height: 20px;
    color: var(--footer-links-heading-color);
    align-self: stretch;
}

.company-links {
    gap: 12px;
}

.link {
    line-height: 24px;
    color: var(--footer-links-color);
    display: flex; 
    flex-direction: row;
    align-items: center; 
    gap: 8px; 
}

.link:hover {
    color: var(--footer-links-hover); 
}

.company-links a{
    display: block;
    position: relative;
    overflow: hidden; 
}

.link p::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -1px;
    width: 100%;
    height: 0.1em;
    background-color: var(--primary-color); 
    opacity: 1;
    transform: translate3d(-100%, 0, 0); 
    transition: opacity 300ms, transform 300ms;
}

.link p:hover::after, .link p:focus::after {
    transform: translate3d(0, 0, 0); 
}

.link p{
    margin-bottom: 0; 
    width: auto; 
}
