
.user-profile-email-display {
    align-items: center;
    display: flex;
    margin-left: 0.5rem;
}


@media (max-width: 768px) {
    .user-profile-menu-text {
        display: none;
    }
}


@media (min-width: 768px) {
    .user-profile-menu-text {
        padding-left: 5px;
    }
}

.user-profile-dropdown-button {
    color: inherit;
    padding: 0;
}

.user-profile-sign-out-modal {
    margin: auto;
}

.user-profile-dropdown-menu {
    z-index: 1999;
}